import { Toolbar, Paper, Typography, Zoom, Grid, TextField, Button, LinearProgress, Divider, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, ButtonGroup, } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { TransitionLeft } from '../../helper/utils'
import { apiFetch } from '../../service/security/apiFetch'
import UploadImg from '../../components/UploadImg'
import { handleFormError } from '../../service/security/error'
import { useStudents } from '../../hook/students'
import { useManagers } from '../../hook/managers'
import AddPopup from './ManageCompaniesItem/AddPopup'
import DeletedConfirmationPopup from '../../components/DeletedConfirmationPopup'
import { isAuth } from '../../service/security/isAuth'

export default function ManageCompanies({setSeverity, setOpenSnackbar, setText, setTransition, zoom, setOpenBackdrop, setUser}) {
    let navigate = useNavigate()
    const [companies, setCompanies] = useState(false)
    const [disable, setDisable] = useState(false)
    const [error, setError] = useState({})
    const [photo, setPhoto] = useState(false)
    const [openAddPopup, setOpenAddPopup] = useState(false)
    const [selected, setSelected] = useState(false)
    const [isManager, setIsManager] = useState(false)
    const [managerFunctions, setManagerFunctions] = useState([])
    const [managerFunction, setManagerFunction] = useState('')
    const [openDeletePopup, setOpenDeletePopup] = useState(false)
    const [state, setState] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        function: '',
    })
    const { id } = useParams()
    document.title = 'Wellmonday - Gérer les entreprises'

    const {
        students,
        fetchStudents,
        updateStudents,
        deleteStudents,
        createStudents,
    } = useStudents()

    const {
        managers,
        fetchManagers,
        updateManagers,
        deleteManagers,
        createManagers,
    } = useManagers()

    useEffect(() => {
        (async () => {
            const fetchCompany = await apiFetch(`/companies/${id}`)
            if (fetchCompany && fetchCompany.uuid) {
                setCompanies(fetchCompany)
            } else {
                setText('Erreur lors du chargement des données !')
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setOpenSnackbar(true)
                navigate(-1)
            }
        })()
    }, [setCompanies, setText, setTransition, setSeverity, setOpenSnackbar, navigate, id])

    useEffect(() => {
        (async () => {
            const fetch = await fetchManagers(`/managers?company.uuid=${id}`)
            if (fetch && fetch['hydra:description']) {
                setText('Erreur lors du chargement des données !')
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setOpenSnackbar(true)
                navigate(-1)
            }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setText, setTransition, setSeverity, setOpenSnackbar, navigate, id])

    useEffect(() => {
        (async () => {
            const fetch = await fetchStudents(`/students?company.uuid=${id}`)
            if (fetch && fetch['hydra:description']) {
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setOpenSnackbar(true)
                navigate(-1)
            }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setText, setTransition, setSeverity, setOpenSnackbar, navigate, id])

    useEffect(() => {
        (async () => {
            const fetchFunction = await apiFetch(`/manager_functions`)
            if (fetchFunction && fetchFunction['hydra:member']) {
                setManagerFunctions(fetchFunction['hydra:member'])
            } else {
                setText('Erreur lors du chargement des données !')
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setOpenSnackbar(true)
                navigate(-1)
            }
        })()
    }, [setManagerFunctions, setText, setTransition, setSeverity, setOpenSnackbar, navigate])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setDisable(true)
        const data = new FormData(e.target)
        const name = data.get('name')
        const address = data.get('address')

        let createPhoto = null
        if (photo) {
            let dataPhoto = new FormData() 
            dataPhoto.append('file', photo && photo[0])
            createPhoto = await apiFetch('/logo_objects', {
                method: 'POST',
                body: dataPhoto,
            }, true)
        }

        if (createPhoto && createPhoto['hydra:description']) {
            setSeverity('error')
            setText(createPhoto['hydra:description'])
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
            setDisable(false)
            return
        }

        let body = { name: name, address: address }
        if (photo) {
            body = { name: name, logo: createPhoto && createPhoto['@id'], address: address }
        }
        
        const manage = await apiFetch('/companies/' + id, {
            method: 'PUT',
            body: JSON.stringify(body),
        })
        if (manage && manage.uuid) {
            setText('L\'entreprise a été modifiée')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
        } else {
            const formError = handleFormError(manage, ['name', 'logo'])
            setError(formError)
            setSeverity('error')
            setText('Erreur lors de l\'ajout d\'une entreprise !')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        }
        
        setDisable(false)
    }

    const handleModifyPopup = (event, row, manager) => {
        setSelected(row)
        setState({
            firstName: row.user.firstName,
            lastName: row.user.lastName,
            email: row.user.email,
            mobile: row.user.mobile,
            function: row.function,
        })
        if (row.function) {
            setManagerFunction(row.function['@id'])
        }
        setIsManager(manager)
        setOpenAddPopup(true)
    }

    const handleConfirmationDelete = (event, row, manager) => {
        setSelected(row)
        setOpenDeletePopup(true)
        setIsManager(manager)
    }

    const manageUsers = async () => {
        setDisable(true)
        setError({})
        const firstName = state.firstName
        const lastName = state.lastName
        const email = state.email
        const mobile = state.mobile

        let body = {user: {firstName: firstName, lastName: lastName, email: email }, company: `/companies/${id}`}
        if (isManager) {
            body = {user: {firstName: firstName, lastName: lastName, email: email, mobile: mobile }, function: managerFunction, company: `/companies/${id}`}
        }

        if (selected) {
            body.user['@id'] = selected.user['@id']
        }

        const manage = selected ? isManager ? await updateManagers(selected.uuid, body, `/managers?company.uuid=${id}`) : await updateStudents(selected.uuid, body, `/students?company.uuid=${id}`) : isManager ? await createManagers(body, `/managers?company.uuid=${id}`) : await createStudents(body, `/students?company.uuid=${id}`)
        if (manage && manage.uuid) {
            setText(isManager ? `Le manager a été ${selected ? 'modifié' : 'créé'}` : `Le salarié a été ${selected ? 'modifié' : 'créé'}`)
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            setOpenAddPopup(false)
            setState({
                firstName: '',
                lastName: '',
                email: '',
                mobile: '',
                function: '',
            })
            setSelected(false)
            setManagerFunction('')
            setIsManager(false)
        } else {
            const formError = handleFormError(manage, ['user.lastName', 'user.firstName', 'user.email', 'user.mobile', 'description'])
            setError(formError)
            setSeverity('error')
            setText('Erreur lors de l\'ajout de l\'utilisateur !')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        }
        setDisable(false)
    }

    const handleClose = () => {
        setOpenAddPopup(false)
        setIsManager(false)
        setSelected(false)
        setError({})
        setState({
            firstName: '',
            lastName: '',
            email: '',
            mobile: '',
            function: '',
        })
        setManagerFunction('')
    }

    const handleOpenAddPopup = (event, manager) => {
        setIsManager(manager)
        setOpenAddPopup(true)
    }

    const handleDelete = async () => {
        setOpenDeletePopup(!openDeletePopup)
        setOpenBackdrop(true)
        const deleted = isManager ? await deleteManagers(selected.uuid, `/managers?company.uuid=${id}`) : await deleteStudents(selected.uuid, `/students?company.uuid=${id}`)

        if (deleted && deleted.ok) {
            setText(`Le ${isManager ? 'manager' : 'salarié'} a été supprimé`)
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
        } else {
            setText((deleted && deleted['hydra:description']) || 'Erreur lors de la suppression')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setOpenBackdrop(false)
        setSelected(false)
        setIsManager(false)
    }

    const handleCloseConfirmationDeletePopup = () => {
        setIsManager(false)
        setOpenDeletePopup(false)
    }

    const onLoginAs = async (e, row) => {
        const loginAs = await apiFetch(`/impersonate?_switch_user=${encodeURIComponent(row.user.email)}`)
        if (loginAs && loginAs.token) {
            localStorage.setItem('REACT_ROLE', JSON.stringify(row.user.roles[0] === 'ROLE_STUDENT' ? 'Student' : 'Manager'))
            localStorage.setItem('REACT_ROLE_ORIGIN', JSON.stringify('Admin'))
            localStorage.setItem('REACT_TOKEN_AUTH_ORIGIN', JSON.stringify(isAuth()))
            localStorage.setItem('REACT_TOKEN_AUTH', JSON.stringify(loginAs))
            navigate(row.user.roles[0] === 'ROLE_STUDENT' ? '/dashboard' : '/profil')
            const fetchUser = await apiFetch('/users/me')
            if (fetchUser && fetchUser['@id']) {
                setUser(fetchUser)
            }
        }
    }

    return (
        <div>
            <Zoom in={zoom} timeout={400} style={{ transitionDelay: '400ms' }}>
                <Paper elevation={16} sx={{padding: '20px 40px', }}>
                    <Toolbar>
                        <Typography variant='h4' color='primary' style={{flex: '1 1 100%'}}>Les Entreprises</Typography>
                        <Button variant='contained' color='primary' onClick={() => navigate(-1)}>Retour</Button>
                    </Toolbar>
                    <Divider sx={{mt: 2}} />
                    {companies ? <div>
                        <form noValidate onSubmit={handleSubmit} autoComplete="off">
                            <Grid container spacing={3} sx={{mt: 2}}>
                                <Grid item xs={12} sm={6}>
                                    <TextField sx={{width: 300}} error={!!error.name} helperText={error.name} label='Raison sociale' id='name' name='name' defaultValue={companies.name} /><br />
                                    <TextField sx={{mt: 4, width: 300}} error={!!error.address} helperText={error.address} label='Adresse' id='address' name='address' defaultValue={companies.address} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <UploadImg setPhoto={setPhoto} title="Ajouter/Changer le logo de l'entreprise" />
                                </Grid>
                            </Grid>
                            <Button sx={{mt: 4}} type='submit' color='primary' variant='contained' disabled={disable}>Enregistrer</Button>
                        </form>
                    </div> : <LinearProgress />}
                </Paper>
            </Zoom>
            <Zoom in={zoom} timeout={700} style={{ transitionDelay: '400ms', marginTop: 40 }}>
                <Paper elevation={16} sx={{padding: '20px 40px', }}>
                    <Typography variant='h4' color='primary'>Référents entreprise</Typography>
                    <Divider sx={{mt: 2}} />
                    {managers ? <div style={{marginTop: 40}}>
                        <Toolbar>
                            <Typography variant='h5' color='primary' sx={{flex: '1 1 100%'}}></Typography>
                            <Button variant='contained' color='primary' onClick={(e) => handleOpenAddPopup(e, true)}>Ajouter</Button>
                        </Toolbar>
                        <BasicTable onLoginAs={onLoginAs} handleModifyPopup={handleModifyPopup} handleConfirmationDelete={handleConfirmationDelete} rows={managers} isManager={true} />
                    </div> : <LinearProgress />}
                </Paper>
            </Zoom>
            <Zoom in={zoom} timeout={700} style={{ transitionDelay: '400ms', marginTop: 40 }}>
                <Paper elevation={16} sx={{padding: '20px 40px', }}>
                    <Typography variant='h4' color='primary'>Salariés à coacher</Typography>
                    <Divider sx={{mt: 2}} />
                    {students ? <div style={{marginTop: 40}}>
                        <Toolbar>
                            <Typography variant='h5' color='primary' sx={{flex: '1 1 100%'}}></Typography>
                            <Button variant='contained' color='primary' onClick={(e) => handleOpenAddPopup(e, false)}>Ajouter</Button>
                        </Toolbar>
                        <BasicTable onLoginAs={onLoginAs} handleModifyPopup={handleModifyPopup} handleConfirmationDelete={handleConfirmationDelete} rows={students} />
                    </div> : <LinearProgress />}
                </Paper>
            </Zoom>
            <AddPopup setManagerFunction={setManagerFunction} managerFunction={managerFunction} managerFunctions={managerFunctions} isManager={isManager} state={state} setState={setState} disable={disable} selected={selected} error={error} open={openAddPopup} handleClose={handleClose} photo={photo} setPhoto={setPhoto} handleAdd={manageUsers} />
            <DeletedConfirmationPopup handleAccept={handleDelete} open={openDeletePopup} handleClose={handleCloseConfirmationDeletePopup} />
        </div>
    )
}

function BasicTable({ rows, isManager, handleModifyPopup, handleConfirmationDelete, onLoginAs }) {

    return (
        <TableContainer>
            <Table aria-label="table">
                <TableHead>
                    <TableRow >
                        <TableCell sx={{color: '#01304A', fontWeight: 'bold'}}>Nom</TableCell>
                        <TableCell sx={{color: '#01304A', fontWeight: 'bold'}} align="right">Prénom</TableCell>
                        <TableCell sx={{color: '#01304A', fontWeight: 'bold'}} align="right">mail</TableCell>
                        {isManager && <TableCell sx={{color: '#01304A', fontWeight: 'bold'}} align="right">Fonction</TableCell>}
                        {isManager && <TableCell sx={{color: '#01304A', fontWeight: 'bold'}} align="right">Téléphone</TableCell>}
                        <TableCell sx={{color: '#01304A', fontWeight: 'bold'}} align="right">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.length ? rows.map((row) => (
                        <TableRow
                            key={row.uuid}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.user.lastName}
                            </TableCell>
                            <TableCell align="right">{row.user.firstName}</TableCell>
                            <TableCell align="right">{row.user.email}</TableCell>
                            {isManager && <TableCell align="right">{(row.function && row.function.name)|| 'Non renseignée'}</TableCell>}
                            {isManager && <TableCell align="right">{row.user.mobile || 'Non renseigné'}</TableCell>}
                            <TableCell align="right">
                                <ButtonGroup variant='contained' color='secondary'>
                                    <Button sx={{color: 'white'}} onClick={(event) => onLoginAs(event, row)}>
                                        Se connecter
                                    </Button>
                                    <Button sx={{color: 'white'}} onClick={(event) => handleModifyPopup(event, row, isManager)}>
                                        Modifier
                                    </Button>
                                    <Button sx={{color: 'white'}} onClick={(event) => handleConfirmationDelete(event, row, isManager)}>
                                        Supprimer
                                    </Button>
                                </ButtonGroup>
                            </TableCell>
                        </TableRow>
                    )) : 
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell align="right"></TableCell>
                        {isManager && <TableCell align="right"></TableCell>}
                        <TableCell align="right">Pas de {isManager ? 'référents' : 'Salariés'}</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>}
                </TableBody>
            </Table>
        </TableContainer>
    );
  }
