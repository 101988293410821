import React, { useState, useRef, useEffect } from "react"
import CustomTuiCalendar from "./PlanningItem/CustomTuiCalendar"
import CustomTuiModal from "./PlanningItem/CustomTuiModal"
import { Typography, CircularProgress } from '@mui/material'
import { useNavigate } from "react-router-dom"
import { TransitionLeft } from "../helper/utils"
import { hasRoles } from "../service/security/hasRoles"
import moment from 'moment'
import { apiFetch } from "../service/security/apiFetch"
import { deleteAvailability, fetchAvailability } from "../service/security/apiPlanningFetch"

const attendees = [
    {id: "1", name: "Chin"},
    { id: "2", name: "Khanh" },
    { id: "3", name: "Linh" }
]

const allDifferentCalendars = {
    Coaching: {
        id: "1",
        name: "Disponibilités",
        color: "#ffffff",
        bgColor: "#01304A",
        dragBgColor: "#4991cc",
        borderColor: "#ECA914"
    },
}

export default function Planning({ setTransition, setOpenSnackbar, setText, setSeverity, setVisioId }) {
    const [modal, setModal] = useState(false)
    const [event, setEvent] = useState(null)
    const [startDate, setStartDate] = useState(false)
    const [endDate, setEndDate] = useState(false)
    const [allShedules, setAllSchedules] = useState(false)
    const [error, setError] = useState({})
    const [specialists, setSpecialists] = useState(false)
    const [defaultProps, setDefaultProps] = useState(false)
    const [repetition, setRepetition] = useState('')
    const [repetitionEndDate, setRepetitionEndDate] = useState('')
    const [selectedSpecialist, setSelectedSpecialist] = useState(null)
    const [ownBookedSchedule, setOwnBookedSchedule] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [editRepetition, setEditRepetition] = useState('only')
    const [filterSchedules, setFilterSchedules] = useState(false);
    const [noForfait, setNoForfait] = useState(false);
    const [calendars, setCalendars] = useState([{
        id: "5",
        name: "Créneaux réservés",
        color: "#ffffff",
        bgColor: "#ffca7f",
        dragBgColor: "#ffca7f",
        borderColor: "#ed961c"
    },{
        id: "1",
        name: "Disponibilités",
        color: "#ffffff",
        bgColor: "#01304A",
        dragBgColor: "#01304A",
        borderColor: "#ed961c"
    }]);
    const childRef = useRef()
    let navigate = useNavigate()
    document.title = 'Wellmonday - Planning'

    const toggle = () => {
        setEditRepetition('only')
        setOwnBookedSchedule(false)
        setStartDate(new Date())
        setModal(!modal)
    }

    function onBeforeCreateSchedule(event) {
        if (hasRoles() !== 'admin') {
            setStartDate(new Date(event.start))
            setEndDate(new Date(event.end))
            setModal(true)
            setEvent(event)
        }
        event.guide.clearGuideElement()
    }

    async function handleCreateSchedule() {
        setError({})
        if (!startDate)
            return setError({startDate: !startDate})

        let newAvailability = null
        if (!repetition) {
            newAvailability = await fetchAvailability({ startDate })
        } else {
            if (!repetitionEndDate) return setError({repetition: true})
            if (ownBookedSchedule) {
                newAvailability = await fetchAvailability({ route: `/availabilities/create/repetition/${repetition}`, startDate, repetition })
            } else {
                newAvailability = await fetchAvailability({ route: `/availabilities/create/repetition/${repetition}`, startDate, repetition })
            }
        }

        if (newAvailability && newAvailability['@id']) {
            const schedule = formatSchedule(newAvailability)
            childRef.current.createSchedule(schedule)
            setModal(false)
            setOwnBookedSchedule(false)
        } else if (Array.isArray(newAvailability) && !newAvailability[0].message) {
            newAvailability.map(availability => childRef.current.createSchedule(formatSchedule(availability)))
            setModal(false)
            setOwnBookedSchedule(false)
            setRepetition('')
        } else {
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setText(newAvailability ? newAvailability[0] ? newAvailability[0].message : newAvailability['hydra:description'] : 'Erreur lors de la création, veuillez reessayer !')
            setOpenSnackbar(true)
        }
    }

    async function onBeforeUpdateSchedule(event) {
        if (event.schedule.bgColor !== "#ffca7f" && hasRoles() !== 'admin') {
            if (event.target) {
                setStartDate(new Date(event.schedule.start))
                setEndDate(new Date(event.schedule.end))
                setModal(true)
                setEvent(event)
            } else {
                const updateAvailability = await fetchAvailability({ route: `${event.schedule.id}`, startDate: event.start._date, isUpdate: true })

                if (updateAvailability && updateAvailability['@id']) {
                    const schedule = formatSchedule(updateAvailability)
                    childRef.current.updateSchedule(event.schedule, schedule)
                } else {
                    setTransition(() => TransitionLeft)
                    setSeverity('error')
                    setText(updateAvailability ? updateAvailability['hydra:description'] : 'Erreur lors de la modification, veuillez reessayer !')
                    setOpenSnackbar(true)
                }
            }
        } else {
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setText('Vous ne pouvez pas modifier ce type de RDV')
            setOpenSnackbar(true)
        }
    }

    async function handleUpdateSchedule() {
        let updateAvailability = null
        if (editRepetition === 'only') {
            updateAvailability = await fetchAvailability({ route: `${event.schedule.id}`, startDate, isUpdate: true })
        } else {
            updateAvailability = await fetchAvailability({ route: `/availabilities/update/repetition`, startDate, isUpdate: true, editRepetition, id: event.schedule.id })
        }

        if (updateAvailability && updateAvailability['@id']) {
            const schedule = formatSchedule(updateAvailability)
            childRef.current.updateSchedule(event.schedule, schedule)
            setModal(false)
        } else if (Array.isArray(updateAvailability)) {
            updateAvailability.map((availability) => {
                availability.calendarId = '1'
                return childRef.current.updateSchedule(filterSchedules.find((schedule) => schedule.id === availability.id), formatSchedule(availability))
            })
            setModal(false)
        } else {
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setText(updateAvailability ? updateAvailability.message || updateAvailability['hydra:description'] : 'Erreur lors de la modification, veuillez reessayer !')
            setOpenSnackbar(true)
        }
        setEditRepetition('only')
    }

    async function onBeforeDeleteSchedule(event) {
        if (hasRoles() !== 'admin') {
            if (event.schedule.calendarId === '5') {
                return setVisioId(event.schedule.body.slots[0].intervention.id)
            }
            if (event.schedule.body.groupHash) {
                setEvent(event)
                setStartDate(new Date(event.schedule.start))
                setEndDate(new Date(event.schedule.end))
                setModal(true)
            } else {
                const deletedAvailability = await deleteAvailability(event.schedule.id)
                if (deletedAvailability && !deletedAvailability['hydra:description']) {
                    const { schedule } = event
                    childRef.current.deleteSchedule(schedule)
                } else {
                    setTransition(() => TransitionLeft)
                    setSeverity('error')
                    setText(deletedAvailability ? deletedAvailability['hydra:description'] : 'Erreur lors de la suppression, veuillez reessayer !')
                    setOpenSnackbar(true)
                }
            }
        } else {
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setText('Vous ne pouvez pas supprimer ce type de RDV')
            setOpenSnackbar(true)
        }
        return true
    }

    async function handleDeleteSchedule() {
        let deletedAvailability = null
        if (editRepetition === 'only') {
            deletedAvailability = await deleteAvailability(event.schedule.id)
        } else {
            deletedAvailability = await deleteAvailability(null, '/availabilities/delete/repetition', event.schedule.id, editRepetition)
        }

        if (deletedAvailability.status === 'success') {
            deletedAvailability.id.map((deletedId) => {
                const filterSchedule = filterSchedules.find((schedule) => schedule.id === deletedId)
                return childRef.current.deleteSchedule(filterSchedule)
            })
            setModal(false)
        } else if (deletedAvailability && !deletedAvailability['hydra:description']) {
            const { schedule } = event
            childRef.current.deleteSchedule(schedule)
            setModal(false)
        } else {
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setText(deletedAvailability ? deletedAvailability['hydra:description'] : 'Erreur lors de la suppression, veuillez reessayer !')
            setOpenSnackbar(true)
        }
    }

    async function initializeAdminView() {
        setCalendars(oldArray => [...oldArray, allDifferentCalendars.Cabinet, allDifferentCalendars.Visio, allDifferentCalendars.Plateforme, allDifferentCalendars.Intervention])
        const specialists = await apiFetch('/specialists/light?pagination=false')
        const allAvailabilites = await apiFetch(`/admin/availabilities?startDate[strictly_after]=${moment().format('YYYY-MM-DD')}&pagination=false`)
        if (specialists && specialists['hydra:member'] && allAvailabilites && allAvailabilites['hydra:member']) {
            const tab = []
            setSpecialists(specialists['hydra:member'])
            setDefaultProps({options: specialists['hydra:member'], getOptionLabel: (option) => `${option.user.firstName} ${option.user.lastName}`})
            allAvailabilites['hydra:member'].map((availability) => {return tab.push(formatSchedule(availability, true))})
            setAllSchedules(tab)
            setFilterSchedules(tab)
        } else {
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setText('Erreur lors de récupération des données, veuillez reessayer')
            setOpenSnackbar(true)
            navigate(-1)
        }
    }

    async function initializeSpecialistView() {
        const allShedulesFetch = await apiFetch(`/availabilities?pagination=false`)
        if (allShedulesFetch && allShedulesFetch['hydra:member']) {
            const tab = []
            allShedulesFetch['hydra:member'].map((availability) => {return tab.push(formatSchedule(availability))})
            setAllSchedules(tab)
            setFilterSchedules(tab)
            setCalendars(allDifferentCalendars[0])
        } else {
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setText('Erreur lors de récupération des données, veuillez reessayer')
            setOpenSnackbar(true)
            navigate(-1)
        }
    }

    useEffect(() => {
        (async () => {
            if (hasRoles() === 'super_admin') {
                await initializeAdminView()
            } else {
                await initializeSpecialistView()
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function formatSchedule(data, isAdmin) {
        const hasBookedSlots = data.status === 'unavailable'

        return {
            id: data['@id'] ? data['@id'] : parseInt(data['@id'].replace( /^\D+/g, '')),
            title: hasBookedSlots ? `Réservé avec ${data.student}` : ``,
            calendarId: hasBookedSlots ? '5' : '1',
            category: "time",
            attendees: _formatAttendees(hasBookedSlots, isAdmin, data),
            start: data.startAt,
            end: data.endAt,
            body: data,
            color: "#ffffff",
            bgColor: hasBookedSlots ? '#ffca7f' : '#01304A',
            dragBgColor: hasBookedSlots ? '#ffca7f' : '#01304A',
            borderColor: "#ECA914",
            recurrenceRule: !!data.groupHash && 'Événement répété',
            state: data.softSkill,
            raw: hasRoles() === 'admin' ? data : null,
            dueDateClass: hasRoles() === 'admin' ? `Coach : ${data.coach.user.firstName} ${data.coach.user.lastName}` : null,
        }
    }

    function _formatAttendees(hasBookedSlots, isAdmin, data) {
        return hasBookedSlots ? [data.student] : isAdmin ? [] : []
    }
  
    function handleOpenModal() {
        setOwnBookedSchedule(true)
        setModal(true)
    }

    async function handleChangeSpecialist(e, value) {
        setAllSchedules(false)
        setFilterSchedules(false)
        setCalendars([{
            id: "5",
            name: "Créneaux réservés",
            color: "#ffffff",
            bgColor: "#ffca7f",
            dragBgColor: "#ffca7f",
            borderColor: "#ed961c"
        },{
            id: "1",
            name: "Disponibilités",
            color: "#ffffff",
            bgColor: "#01304A",
            dragBgColor: "#01304A",
            borderColor: "#ed961c"
        }])

        if (!value) {
            setSelectedSpecialist(null)
            return await initializeAdminView()
        }

        const specialistFetch = await apiFetch(`/specialists/${value.id}`)
        const tab = []
        if (specialistFetch && specialistFetch.id) {
            specialistFetch.readableConsultationType.map((consultationType) => {
                return setCalendars(oldArray => [...oldArray, allDifferentCalendars[consultationType.name]])
            })
            specialistFetch.displayableAvailabilities.map((availability) => {return tab.push(formatSchedule(availability, false, true))})
            setAllSchedules(tab)
            setFilterSchedules(tab)
            setSelectedSpecialist(value)
        } else {
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setText('Erreur lors de récupération des données, veuillez reessayer')
            setOpenSnackbar(true)
            navigate(-1)
        }
    }

    return (
        <div>
            <Typography variant='body1' color='secondary' style={{textAlign: 'center', margin: 10}}>Pour ajouter vos disponibilités, cliquez sur le planning et remplissez la plage horaires de votre choix.</Typography>
            {filterSchedules && allShedules ? <CustomTuiCalendar
                ref={childRef}
                {...{
                    isReadOnly: false,
                    showSlidebar: true,
                    showMenu: true,
                    useCreationPopup: false,
                    calendars,
                    schedules: allShedules,
                    filterSchedules,
                    setFilterSchedules,
                    onBeforeCreateSchedule,
                    onBeforeUpdateSchedule,
                    onBeforeDeleteSchedule,
                    toggle,
                    handleOpenModal,
                    specialists,
                    defaultProps,
                    handleChangeSpecialist,
                    selectedSpecialist,
                }}
            /> : <div style={{textAlign: 'center', marginTop: 50}}><CircularProgress color='primary' /></div>}
            <CustomTuiModal
                {...{
                    isOpen: modal,
                    toggle,
                    onSubmit:
                        event?.triggerEventName === "mouseup"
                            ? handleCreateSchedule
                            : event?.deleteGroup ? 
                            handleDeleteSchedule
                            : event?.triggerEventName === "click" ? 
                            handleUpdateSchedule
                            : handleCreateSchedule,
                    submitText: event?.triggerEventName === "mouseup" ? "Enregistrer" : event?.deleteGroup ? 'Supprimer' : event?.triggerEventName === "click" ? "Modifier" : 'Enregistrer',
                    calendars,
                    attendees,
                    selectedSchedule: event && event.schedule,
                    setStartDate,
                    setEndDate,
                    error,
                    repetition,
                    setRepetition,
                    repetitionEndDate,
                    setRepetitionEndDate,
                    editRepetition,
                    setEditRepetition,
                    startDate,
                    endDate,
                    ownBookedSchedule,
                    firstName,
                    setFirstName,
                    lastName,
                    setLastName,
                    setNoForfait,
                    noForfait,
                }}
            />
        </div>
    )
}
