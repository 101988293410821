import React, { useEffect, useState } from 'react'
import {
    Zoom,
    Paper,
    Typography,
    Grid,
    MobileStepper,
    Button,
    Avatar,
    Tooltip,
    IconButton,
    Toolbar,
} from '@mui/material/';
import { apiFetch } from '../../../service/security/apiFetch';
import { useNavigate, useParams } from 'react-router-dom';
import { download, getArrayOfSeanceEnded, shouldShowSignedButton, TransitionLeft } from '../../../helper/utils';
import moment from 'moment';
import ToDo from '../../DashboardItem/StudentItem/ToDo';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import EmailIcon from '@mui/icons-material/Email';
import TvIcon from '@mui/icons-material/Tv';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ResumePopup from './StudentCoachingDetailItem/ResumePopup';
import { handleSignePopup } from '../../../helper/CoachingActions';

export default function StudentCoachingDetail({ zoom, setOpenSnackbar, setText, setTransition, setSeverity, setOpenBackdrop, setOpenSignePopup }) {
    const { id } = useParams()
    const [coaching, setCoaching] = useState(false)
    const [openResumePopup, setOpenResumePopup] = useState(false)
    const [disable, setDisable] = useState(false)
    let navigate = useNavigate()

    useEffect(() => {
        (async () => {
            const fetchCoaching = await apiFetch(`/coachings/${id}`)
            if (fetchCoaching && fetchCoaching.uuid) {
                setCoaching(fetchCoaching)
            } else {
                setSeverity('error')
                setText('Erreur lors du chargement des données')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                navigate(-1)
            }
        })()
    }, [id, navigate, setSeverity, setText, setTransition, setOpenSnackbar])

    const handleVisio = (row) => {
        window.open(row.visioUrl, "_blank")
    }

    const handleResume = (row) => {
        setOpenResumePopup(row)
    }

    const handleMessage = () => {
        navigate('/messages')
    }

    const handleDownload = async (document) => {
        setDisable(true)
        const amazonLink = await apiFetch(document.downloadUrl, {}, false, false, false, false, true)

        if (amazonLink && amazonLink.signedUrl) {
            const aws3Doc = await apiFetch(`${amazonLink.signedUrl}`, {method: 'GET'}, true, true, true)
            if (aws3Doc.ok) {
                const blob = await aws3Doc.blob()
                download(blob, `${document.name} - ${moment(document.createdAt).format('DD MMMM YYYY')}`)
            }
        }
        setDisable(false)
    }

    const handleBack = () => {
        navigate(-1)
    }

    return (
        <Zoom in={zoom} timeout={700}>
            <div>
                <Toolbar>
                    <div style={{flex: '1 1 100%'}}>
                        <Button onClick={handleBack} variant='contained' color='primary' sx={{mb: 5}}>Retour</Button>
                    </div>
                    {coaching && shouldShowSignedButton(coaching) && <Button disabled={disable} variant='contained' color='secondary' sx={{color: 'white', mb: 5}} onClick={() => handleSignePopup({coaching, setOpenBackdrop, setOpenSignePopup, setTransition, setText, setSeverity, setOpenSnackbar})}>Signer le contrat de coaching</Button>}
                </Toolbar>
                <ResumePopup open={openResumePopup} handleClose={() => setOpenResumePopup(false)} />
                {coaching && <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Zoom in={zoom} timeout={700} style={{ transitionDelay: '200ms' }}>
                            <Paper elevation={3} sx={{padding: 3}}>
                                <Typography variant='h4' color='secondary' style={{fontWeight: 'bold'}}>Mes séances</Typography>
                                <Typography variant='h6' color='secondary' style={{fontWeight: 'bold'}}>{(getArrayOfSeanceEnded(coaching.seances) && getArrayOfSeanceEnded(coaching.seances).length)} séances réalisées sur 7</Typography>
                                <MobileStepper
                                    variant="progress"
                                    steps={coaching.nbSeances + 1}
                                    position="static"
                                    activeStep={((getArrayOfSeanceEnded(coaching.seances) && getArrayOfSeanceEnded(coaching.seances).length)) || 0}
                                    sx={{ maxWidth: '100%', mt: 1, flexGrow: 1, backgroundColor: '#fff' }}
                                    LinearProgressProps={{style: {width: '100%', height: '10px'}}}
                                />
                            </Paper>
                        </Zoom>
                    </Grid>
                    <Grid item xs={6}>
                        <Zoom in={zoom} timeout={700} style={{ transitionDelay: '400ms' }}>
                            <Paper elevation={3} sx={{paddingTop: 3, paddingLeft: 3, paddingRight: 3, paddingBottom: 1}}>
                                <Grid container spacing={3} sx={{textAlign: 'center', marginBottom: 2}}>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant='h6' color='secondary' style={{fontWeight: 'bold'}}>Séances</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant='h6' color='secondary' style={{fontWeight: 'bold'}}>Date</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant='h6' color='secondary' style={{fontWeight: 'bold'}}>Actions</Typography>
                                    </Grid>
                                </Grid>
                                {coaching.seances && coaching.seances.length ? coaching.seances.map((seance, index) => {
                                    return (
                                        <Grid container spacing={3} key={index} sx={{textAlign: 'center', marginBottom: 2}}>
                                            <Grid item xs={12} sm={4}>
                                                <Typography variant='h6' color='secondary' style={{fontWeight: 'bold'}}>Séance {index + 1}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {moment(seance.availability.startAt).format('DD MMMM YYYY à HH:mm')}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {seance.visioUrl ? <Tooltip title='Rejoindre la visio-consultation'><IconButton onClick={() => handleVisio(seance)} ><TvIcon /></IconButton></Tooltip> : seance.noteSeance ? <IconButton onClick={() => handleResume(seance)}><VisibilityIcon /></IconButton> : !seance.visioUrl && coaching.nextSeance && <Tooltip title='Vous pouvez rejoindre la visio à partir de 5 min avant le début de la séance'><span><IconButton disabled><TvIcon /></IconButton></span></Tooltip>}
                                            </Grid>
                                        </Grid>
                                    )
                                }) : <Typography variant='h6' style={{fontStyle: 'italic'}}>Pas de séance pour le moment. {coaching.coach ? 'Vous pouvez planifier une séance.' : 'Vous devez compléter la démarche de choix du coach avant de planifier vos séances.'} </Typography>}
                            </Paper>
                        </Zoom>
                    </Grid>
                    <Grid item xs={6}>
                        {coaching.coach && <Zoom in={zoom} timeout={700} style={{ transitionDelay: '500ms' }}>
                            <Paper elevation={3} sx={{padding: 3}} style={{margin: 'auto', textAlign: 'center'}}>
                                <Typography variant='h4' color='secondary' style={{fontWeight: 'bold'}}>Mon coach</Typography>
                                <Avatar alt='N' src={coaching.coach && coaching.coach.photo && coaching.coach.photo.contentUrl} style={{width: 150, height: 150, margin: 'auto', marginTop: 40}} />
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <Typography variant='h5' color='secondary' style={{fontWeight: 'bold', marginTop: 40}}>{coaching.coach.user.firstName} {coaching.coach.user.lastName[0]}.</Typography>
                                    <EmailIcon style={{marginLeft: 30, width: 30, height: 30, marginTop: 40, cursor: 'pointer'}} onClick={handleMessage} />
                                </div>
                                {!coaching.nextSeance && coaching.nbSeancesLeft && <Button variant={'contained'} color='secondary' style={{marginTop: 40, color: 'white'}} onClick={() => navigate('/prendre-un-rdv')}>planifier une séance</Button>}
                            </Paper>
                        </Zoom>}
                    </Grid>
                    <Grid item xs={6}>
                        <ToDo zoom={zoom} dashboardData={{currentCoaching: coaching}} setOpenBackdrop={setOpenBackdrop} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} />
                    </Grid>
                    <Grid item xs={6}>
                        {coaching.documents && !!coaching.documents.length && <Zoom in={zoom} timeout={700} style={{ transitionDelay: '500ms' }}>
                            <Paper elevation={3} sx={{padding: 3, textAlign: 'center'}}>
                                <Typography variant='h4' color='secondary' style={{fontWeight: 'bold', marginBottom: 40}}>Documents</Typography>
                                {coaching.documents.map((document) => {
                                    return <Grid key={document.id} container spacing={3} sx={{margin: 'auto'}}>
                                        <Grid item xs={12} sm={3}>
                                            <IconButton onClick={() => handleDownload(document)} disabled={disable} color='primary' style={{cursor: 'pointer', marginTop: '-5px'}} size='large'>
                                                <AttachFileIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={12} sm={9}>
                                            <Typography variant='h6' color='secondary' style={{textAlign: 'left', fontWeight: 'bold'}}>{document.name}</Typography>
                                        </Grid>
                                    </Grid>
                                })}
                            </Paper>
                        </Zoom>}
                    </Grid>
                </Grid>}
            </div>
        </Zoom>
    )
}