import { Navigate } from 'react-router-dom'
import { isAuth } from './isAuth'

export const RequireAuth = ({ children }) => {

    if (!isAuth()) {
        return <Navigate to='/login' /> 
    }

    return children
}