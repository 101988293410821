import React, { useState } from 'react'
import { Typography, Grid, TextField, InputAdornment, IconButton, Link } from "@mui/material";
import loginLogo from '../../img/teaching.svg'
import logo from '../../img/logo.png'
import { Visibility, VisibilityOff, LockOpen } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { isKnownRoutes, isNotConnectedRoutes, TransitionLeft } from '../../helper/utils';
import { login } from '../../service/security/handleTokenProvider'
import AlertSnackbar from '../../components/AlertSnackbar';
import { apiFetch } from '../../service/security/apiFetch';
import LoadingButton from '@mui/lab/LoadingButton';
import useWidth from '../../hook/useWith';

export default function Login() {
    const [showPassword, setShowPassword] = useState(false)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [transition, setTransition] = useState(false)
    const [severity, setSeverity] = useState(false)
    const [message, setMessage] = useState(false)
    const width = useWidth()
    let navigate = useNavigate()
    document.title = 'Wellmonday - Connexion'
    
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleSubmit = (e) => {
        setLoading(true)
        e.preventDefault()
        let data = new FormData(e.target)
        const email = data.get('username')
        const pass = data.get('password')

        try {
            data = { username: `${email}`, password: `${pass}` } 
            login(data).then((response) => {
                response.json().then((responseData) => {
                    localStorage.setItem('REACT_TOKEN_AUTH', JSON.stringify(responseData))
                    if(response.ok) {
                        apiFetch('/users/me').then((res) => {
                            if (res && res['@id']) {
                                localStorage.setItem('REACT_ROLE', JSON.stringify(res.type))
                                if (localStorage.getItem('LAST_URL') && isKnownRoutes(localStorage.getItem('LAST_URL')) && !isNotConnectedRoutes(window.location.pathname)) {
                                    navigate(localStorage.getItem('LAST_URL'))
                                } else {
                                    navigate('/dashboard')
                                }
                            }
                        })
                    } else {
                        setLoading(false)
                        if (responseData.code === 401) {
                            setMessage(responseData.message || 'Nom d\'utilisateur ou mot de passe incorrect')
                            setSeverity('error')
                            setTransition(() => TransitionLeft)
                            setError(true)
                        } else {
                            setMessage(responseData.detail)
                            setSeverity('error')
                            setTransition(() => TransitionLeft)
                            setError(true)
                        }
                    }
                })
            }).catch((e) => {
                setError(e)
            })
        } catch (e) {
            setError(e)
            setLoading(false)
        }
    }

    const handleResetPassword = (e) => {
        e.preventDefault()
        navigate('/reset-password')
    }

    return (
        <div>
            <Grid container>
                <Grid item xs={12} sm={8} >
                    <div style={{display: '-webkit-box', margin: '2rem', float: 'left'}}>
                        <img src={logo} alt='logo' width='180px' />
                    </div>
                    <img src={loginLogo} alt="COACHING DIGITAL" style={width === 'xs' ? {marginTop: 0} : width === 'md' ? {marginLeft: 60, marginTop: 100} : width === 'sm' ? {marginLeft: 60, marginTop: 100} : {marginTop: 100}}  width={width === 'xs' ? '100%' : width === 'lg' ? '69%' : width === 'md' ? '80%' : width === 'sm' ? '80%' : '70%'}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <div style={{height: window.innerHeight, boxShadow: width === 'xs' ? '' : 'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px', padding: width === 'xs' ? 5 : 60, paddingTop: width === 'xs' ? 10 : 300}}>
                        <Typography variant='h5' color={'primary'} style={{fontSize: 23, fontWeight: 'bold', letterSpacing: 1, marginBottom: 15}}>
                            Bienvenue sur l'espace Wellmonday ! 
                        </Typography>
                        <Typography variant='h6' color={'primary'} style={{fontSize: 15}}>
                            Connectez-vous à votre compte pour accéder à l'application.
                        </Typography>
                        <form noValidate onSubmit={handleSubmit} autoComplete="on">
                            <TextField color={'primary'} autoComplete='username' id="username" name="username" label="Email" sx={{width: '100%', marginTop: 3.75}} /><br />
                            <TextField 
                                id="password" 
                                color={'primary'}
                                name="password" 
                                label="Mot de passe"
                                sx={{width: '100%', marginTop: 1.8}}
                                type={showPassword ? "text" : "password"}
                                autoComplete='current-password'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                          </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            /><br />
                            <LoadingButton
                                loading={loading}
                                type="submit"
                                variant="contained"
                                color={'primary'}
                                sx={{marginTop: 1.8, width: '100%', height: 50, marginBottom: 1}}
                                endIcon={<LockOpen />}
                            >
                                Se connecter
                            </LoadingButton>
                            <Link href="#" onClick={handleResetPassword} variant="body2">
                                Mot de passe oublié
                            </Link>
                        </form>
                    </div>
                </Grid>
            </Grid>
            <AlertSnackbar open={!!error} setOpen={setError} transition={transition} message={message} severity={severity} />
        </div>
    )
}