export function logout() {
    window.location.pathname = '/'
    localStorage.removeItem('REACT_TOKEN_AUTH')
    localStorage.removeItem('REACT_TOKEN_AUTH_ORIGIN')
    localStorage.removeItem('REACT_ROLE_ORIGIN')
    localStorage.removeItem('LAST_URL')
    localStorage.removeItem('REACT_ROLE')
}

export function login(identifier) {
    return fetch(
        process.env.REACT_APP_BASE_URL + '/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(identifier)
        }
    )
}