import React, {useState, useEffect} from 'react'
import {
    Grid,
    Typography,
    Paper,
    Divider,
    CircularProgress,
    Zoom,
} from '@mui/material';
import { StyledTooltip, TransitionLeft } from '../../helper/utils';
import { useNavigate } from 'react-router-dom';
import { apiFetch } from '../../service/security/apiFetch';
import Ressources from './StudentToolboxItem/Ressources';

export default function CoachToolbox({zoom, setOpenSnackbar, setText, setTransition, setSeverity, setOpenBackdrop}) {
    const [tab, setTab] = useState(false)
    const [ressources, setRessources] = useState(false)
    let navigate = useNavigate()

    useEffect(() => {
        (async () => {
            const softSkill = await apiFetch('/soft_skills')
            if (softSkill && softSkill['hydra:member']) {
                setTab(softSkill['hydra:member'])
            } else {
                setText('Erreur lors du chargement des données')
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setOpenSnackbar(true)
                navigate(-1)
            }
        })()
    }, [setOpenSnackbar, setTransition, setText, setSeverity, navigate])

    const handleClickIcon = async (el) => {
        setOpenBackdrop(true)
        const getRessources = await apiFetch(`/resources?softSkills.uuid=${el.uuid}`)
        if (getRessources && getRessources['hydra:member']) {
            setRessources({main: el, ressources: getRessources['hydra:member']})
        } else {
            setText('Erreur lors du chargement des données')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
            navigate(-1)
        }
        setOpenBackdrop(false)
    }
    
    return (
        <>
            {!ressources ? <Zoom in={zoom} timeout={700}>
                <div>
                    <Typography variant='h4' color='secondary' sx={{textAlign: 'left', mt: 2, pt: 2, fontWeight: 'bold'}}>LES OUTILS DU COACH </Typography>
                    <Divider style={{backgroundColor: '#ECA914'}}/>
                    <Typography variant='h6' sx={{textAlign: 'left', mt: 2, pt: 2}}>Consultez les ressources à conseiller :</Typography>
                    {tab ? <Paper elevation={16} sx={{maxWidth: 1100, margin: 'auto', textAlign: 'center', pb: 3, }}>
                        <Grid container spacing={4} sx={{textAlign: 'center', mt: 4}}>
                            {tab.map((el, index) => (
                                <StyledTooltip key={index} title={el.name}>
                                    <Grid item xs={12} sm={4} md={3} sx={{mb: 4}}>
                                        <img src={el.isSelected ? el.iconVariant.contentUrl : el.icon.contentUrl} alt={el.name} width={'130px'} style={{cursor: 'pointer'}} onClick={() => handleClickIcon(el)} />
                                    </Grid>
                                </StyledTooltip>
                            ))}
                        </Grid>
                    </Paper> : 
                    <div style={{textAlign: 'center', marginTop: 25}}>
                        <CircularProgress color='primary' />    
                    </div>}
                </div>
            </Zoom> :
            ressources && <Ressources zoom={zoom} ressources={ressources} setRessources={setRessources} />}
        </>
    )
}