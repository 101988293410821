import { Button, Link, Slide, Snackbar, TextField, Typography, Paper, Zoom } from '@mui/material'
import React from 'react'
import MuiAlert from '@mui/material/Alert';
import { apiFetch } from '../service/security/apiFetch';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { useNavigate } from 'react-router-dom';

function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
}

export function ResetPassword({ zoom }) {
    const [disable, setDisable] = React.useState(false)
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [transition, setTransition] = React.useState(undefined);
    let navigate = useNavigate()

    const handleClose = () => {
      setOpen(false);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault()
        setDisable(true)
        const data = new FormData(e.target)
        const email = { email : `${data.get('username')}` }
        const reset = await apiFetch(
            '/reset-password/email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(email)
        }, true)
        if (reset[0]) {
            setError(reset[0])
        } else {
            setTransition(() => TransitionLeft)
            setOpen(true)
            setTimeout(() => {
                navigate(-1)
            }, 5000)
        }
        setDisable(false)
    }

    const handleBack = (e) => {
        e.preventDefault()
        navigate('/login')
    }


    return (
        <div style={{width: '100%', height: '100%'}}>
            <Paper elevation={16} sx={{mt: 3, padding: 4,  width: 600, height: 185, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                <Zoom in={true} timeout={700}>
                    <div>
                        <Typography variant='body1' sx={{marginBottom: 1}}>Veuillez saisir votre email pour pouvoir réinitialiser votre mot de passe</Typography>
                        <form noValidate onSubmit={ handleFormSubmit }>
                            <TextField sx={{width: 350}} error={!!error.field} helperText={error.message} id="username" name="username" label="Email" style={{marginRight: '50px'}}/>
                            <Button
                                disabled={disable}
                                type="submit"
                                variant="contained"
                                color="primary"
                                endIcon={<VpnKeyIcon />}
                                style={{marginTop: '15px'}}>
                                    Reset
                            </Button>
                            <Typography style={{paddingTop: '20px'}}>
                                <Link href="#" onClick={handleBack} variant="body2">
                                    Retourner à l'écran de connexion
                                </Link>
                            </Typography>
                            {open && <Snackbar
                                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                                open={open}
                                onClose={handleClose}
                                TransitionComponent={transition}
                                key={transition ? transition.name : ''}
                            >
                                <MuiAlert elevation={6} variant="filled" severity="success" >Un email de confirmation vient d'être envoyé sur votre boîte mail</MuiAlert>
                            </Snackbar>}
                        </form>
                    </div>
                </Zoom>
            </Paper>
        </div>
    )
}