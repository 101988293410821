import React from 'react'
import {
    Typography,
    Paper,
    Button,
    Zoom,
    ButtonGroup,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    TextField,
    FormHelperText,
    useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Divider } from '@mui/material';
import { PieRadius } from '../HexacoChart';
import LoadingButton from '@mui/lab/LoadingButton';
import DownloadIcon from '@mui/icons-material/Download';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import { blue } from '@mui/material/colors';

export default function SelfDiagnosis({quizSumUp, zoom, handleNext, handleBack, startSelfDiagnosis, coaching, questions, handleSubmitQuestion, formRef, age, setAge, gender, setGender, error, handleDownload, disable, isDownloaded }) {
    let navigate = useNavigate()
    const matches1500 = useMediaQuery('(max-width:1500px)');
    const matches1300 = useMediaQuery('(max-width:1300px)');

    return (
        <Zoom in={zoom} timeout={700} style={{ transitionDelay: '500ms' }}>
            <Paper elevation={16} sx={{maxWidth: 1200, margin: 'auto', textAlign: 'center', p: {sm: '10px 100px 20px 100px', xs: '10px 25px 10px 25px'},  height: quizSumUp && 1100}} >
                {!startSelfDiagnosis && coaching.hexacoQuiz.status === 'created' ? <>
                    <Typography variant='h4' sx={{textAlign: 'center', mt: 2, pt: 2}} color='primary'>
                        EVALUEZ VOS SOFT SKILLS
                    </Typography>
                    <Typography variant='h6' sx={{textAlign: 'left', mt: 2, pt: 2}} color='secondary'>
                        Voici la première étape de votre parcours de coaching ! Réalisez votre autodiagnostic des soft skills grâce à ce test scientifiquement validé. Le test HEXACO, créé par Kibeom Lee & Michael C. Ashton, chercheurs en psychologie à l'Université de Calgary, se compose de 100 questions.
                    </Typography>
                    <Typography variant='h6' sx={{textAlign: 'left', mt: 2, pt: 2, fontWeight: 'bold'}} color='secondary'>
                        Prévoyez une vingtaine de minutes.
                    </Typography>
                    <Typography variant='h6' sx={{textAlign: 'left', mt: 2, pt: 2}} color='secondary'>
                        Voici une série d’énoncés qui vous décrivent. Veuillez lire chaque énoncé et décider à quel point vous êtes d’accord ou en désaccord avec son contenu. Ensuite, cochez le chiffre approprié, en vous fondant sur l’échelle suivante :
                    </Typography>
                    {!matches1500 ? <FormControl sx={{mt: 2}}>
                        <RadioGroup
                            aria-labelledby="radio-buttons-group-label"
                            row
                        >
                            <FormControlLabel disabled value={1} control={<Radio />} labelPlacement="start" sx={{'& .MuiSvgIcon-root': {fontSize: 20, color: '#01304A'}, '& .MuiFormControlLabel-label': {color: 'black !important'}}} label="Pas du tout d'accord" />
                            <FormControlLabel disabled value={2} control={<Radio />} labelPlacement="start" sx={{'& .MuiSvgIcon-root': {fontSize: 20, color: '#01304A'}, '& .MuiFormControlLabel-label': {color: 'black !important'}}} label="pas d'accord" />
                            <FormControlLabel disabled value={3} control={<Radio />} labelPlacement="bottom" sx={{paddingTop: 3, '& .MuiSvgIcon-root': {fontSize: 20, color: '#ECA914'}, '& .MuiFormControlLabel-label': {color: 'black !important'}}} label="neutre (ni d'accord, ni en désaccord)" />
                            <FormControlLabel disabled value={4} control={<Radio />} labelPlacement="end" sx={{'& .MuiSvgIcon-root': {fontSize: 20, color: blue[800]}, '& .MuiFormControlLabel-label': {color: 'black !important'}}} label="d'accord" />
                            <FormControlLabel disabled value={5} control={<Radio />} labelPlacement="end" sx={{'& .MuiSvgIcon-root': {fontSize: 20, color: blue[800]}, '& .MuiFormControlLabel-label': {color: 'black !important'}}} label="Tout à fait d'accord " />
                        </RadioGroup>
                    </FormControl> : 
                    <FormControl sx={{mt: 2, width: '100%', textAlign: 'left'}}>
                        <RadioGroup
                            aria-labelledby="radio-buttons-group-label"
                        >
                            <FormControlLabel disabled value={1} control={<Radio />} sx={{'& .MuiSvgIcon-root': {fontSize: 30, color: '#01304A'}, '& .MuiFormControlLabel-label': {color: 'black !important'}}} label="Pas du tout d'accord" />
                            <FormControlLabel disabled value={2} control={<Radio />} sx={{'& .MuiSvgIcon-root': {fontSize: 30, color: '#01304A'}, '& .MuiFormControlLabel-label': {color: 'black !important'}}} label="pas d'accord" />
                            <FormControlLabel disabled value={3} control={<Radio />} sx={{paddingTop: 3, '& .MuiSvgIcon-root': {fontSize: 30, color: '#ECA914'}, '& .MuiFormControlLabel-label': {color: 'black !important'}}} label="neutre (ni d'accord, ni en désaccord)" />
                            <FormControlLabel disabled value={4} control={<Radio />} sx={{'& .MuiSvgIcon-root': {fontSize: 30, color: blue[800]}, '& .MuiFormControlLabel-label': {color: 'black !important'}}} label="d'accord" />
                            <FormControlLabel disabled value={5} control={<Radio />} sx={{'& .MuiSvgIcon-root': {fontSize: 30, color: blue[800]}, '& .MuiFormControlLabel-label': {color: 'black !important'}}} label="Tout à fait d'accord " />
                        </RadioGroup>
                    </FormControl>}
                    <br />
                    <Typography variant='h6' sx={{textAlign: 'left', mt: 2, pt: 2}} color='secondary'>
                        Veuillez répondre à tous les énoncés, même si vous n’êtes pas tout à fait certain(e) de votre réponse.
                    </Typography>
                    <Typography variant='h6' sx={{textAlign: 'left', mt: 2, pt: 2}} color='secondary'>
                        Avant de commencer, veuillez indiquer votre sexe et votre âge.
                    </Typography>
                    <div style={{textAlign: 'left', marginTop: 16, paddingTop: 16}}>
                        <FormControl error={!!error.sexe}>
                            <FormLabel id="row-radio-buttons-group-label">Êtes-vous</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                            >
                                <FormControlLabel value="Féminin" control={<Radio />} label="Une femme" />
                                <FormControlLabel value="Masculin" control={<Radio />} label="Un homme" />
                            </RadioGroup>
                            <FormHelperText sx={{color: 'red'}}>{error.sexe}</FormHelperText>
                        </FormControl><br />
                        <TextField
                            id="lastName"
                            value={age}
                            onChange={(e)=>setAge(e.target.value)}
                            label="Quel âge avez-vous ?"
                            type="text"
                            error={!!error.age}
                            helperText={error.age}
                            sx={{mt: 2, pt: 2}}
                        /> <br />
                    </div>
                    <ButtonGroup variant="contained" sx={{height: 50, mt: 3}} >
                        <Button disabled={disable} onClick={() => navigate('/')}>Plus tard</Button>
                        <Button disabled={disable} onClick={handleBack}>Revenir à l'étape précédente</Button>
                        <Button disabled={disable} onClick={handleNext} sx={{width: 150}}>Faire le test</Button>
                    </ButtonGroup>
                </> : 
                    quizSumUp ? 
                    <>
                        <Typography variant='h4' sx={{textAlign: 'center', mt: 2, pt: 2}} color='primary'>
                            EVALUEZ VOS SOFT SKILLS
                        </Typography>
                        <Typography variant='h6' sx={{textAlign: 'center', mt: 2, pt: 2, fontWeight: 'bold'}} color='secondary'>
                            Bravo ! Le test est terminé.
                        </Typography>
                        <Typography variant='h6' sx={{textAlign: 'center', mt: 2, pt: 2, fontWeight: 'bold'}} color='secondary'>
                            Voici vos résultats:
                        </Typography>
                        <div>
                            <PieRadius quizHexaco={quizSumUp.dimensionResults} />
                        </div>
                        <Typography variant='h6' sx={{textAlign: 'center', mt: 35, pt: 2, fontWeight: 'bold'}} color='secondary'>
                            Votre coach vous aidera à analyser ces résultats en vous apportant son expertise.
                        </Typography>
                        <div style={{textAlign: 'center', marginTop: 20}}>
                            <ButtonGroup variant="contained" color='primary' sx={{height: 50}} >
                                <LoadingButton endIcon={isDownloaded ? <DownloadDoneIcon /> : <DownloadIcon />} variant='contained' color='primary' loading={disable} onClick={handleDownload}>Télécharger</LoadingButton>
                                <Button disabled={disable} onClick={() => handleNext({nextQuestions: []})}>Continuer vers le choix du coach</Button>
                            </ButtonGroup>
                        </div>
                    </>
                    : <>
                        <Typography>Étape {questions.step}/10</Typography>
                        <form ref={formRef} id='diagnosisForm' autoComplete="off" onSubmit={ handleSubmitQuestion }>
                        {questions && questions.nextQuestions && questions.nextQuestions.map((question, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <FormControl style={{marginTop: 60}}>
                                        <FormLabel id="radio-buttons-group-label" style={{color: 'black', fontSize: 20, marginBottom: 20}}>{question.question.text}</FormLabel>
                                        <RadioGroup
                                            aria-labelledby="radio-buttons-group-label"
                                            name={`${question.uuid}`}
                                            row={!matches1300}
                                            style={{marginTop: 20, margin: 'auto'}}
                                            id={question.question.index}
                                        >
                                            <FormControlLabel value={1} control={<Radio sx={{color: '#01304A'}} color="secondary" />} labelPlacement={!matches1300 ? "start" : "end"} sx={{marginRight: 5, '& .MuiSvgIcon-root': {fontSize: 30,},}} label="Pas du tout d'accord" />
                                            <FormControlLabel value={2} control={<Radio sx={{color: '#01304A'}} color="secondary" />} sx={{marginRight: 5, '& .MuiSvgIcon-root': {fontSize: 30,},}} label={!matches1300 ? "" : "pas d'accord"} />
                                            <FormControlLabel value={3} control={<Radio sx={{color: '#ECA914'}} color="primary" />} sx={{marginRight: 5, '& .MuiSvgIcon-root': {fontSize: 30,},}} label={!matches1300 ? "" : "neutre (ni d'accord, ni en désaccord)"} />
                                            <FormControlLabel value={4} control={<Radio sx={{color: blue[800],'&.Mui-checked': {color: blue[600],},}} />} sx={{marginRight: 5, '& .MuiSvgIcon-root': {fontSize: 30,},}} label={!matches1300 ? "" : "d'accord"} />
                                            <FormControlLabel value={5} control={<Radio sx={{color: blue[800],'&.Mui-checked': {color: blue[600],},}} />} sx={{marginRight: 5, '& .MuiSvgIcon-root': {fontSize: 30,},}} label="Tout à fait d'accord " />
                                        </RadioGroup>
                                    </FormControl><br />
                                    <Divider sx={{mt: 5}} />
                                </React.Fragment>
                            )
                        })}
                        <div style={{textAlign: 'right', marginTop: 30}}>
                            <Button variant='contained' color='primary' type='submit'>{questions.step === 10 ? 'Terminer' : 'Suivant'}</Button>
                        </div>
                        </form>
                    </>
                }
            </Paper>
        </Zoom>
    )
}