import React, {useState, useEffect} from 'react'
import { Zoom, Paper, Typography, Grid, TextField, Button, IconButton, Avatar, Badge } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import PhotoPopup from './PhotoPopup'
import { TransitionLeft } from '../../helper/utils';
import { styled } from '@mui/material/styles';
import { apiFetch } from '../../service/security/apiFetch';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

export default function StudentProfil({ zoom, user, setOpenSnackbar, setText, setTransition, setSeverity }) {
    const CssIconButton = styled(IconButton)({
        backgroundColor: '#ECA914',
        color: 'white',
        "&:hover": { 
            backgroundColor: "white",
            color: '#ECA914'
        },
    });
    const [avatars, setAvatars] = useState([])
    const [openPhotoPopup, setOpenPhotoPopup] = useState(false)
    const [disable, setDisable] = useState(true)
    const [photo, setPhoto] = useState(false)
    let navigate = useNavigate()

    useEffect(() => {
        (async () => {
            const fetchAvatars = await apiFetch('/avatar_objects')
            if (fetchAvatars && fetchAvatars['hydra:member']) {
                setAvatars(fetchAvatars['hydra:member'])
            } else {
                setTransition(() => TransitionLeft)
                setText('Erreur lors du chargement des données')
                setSeverity('error')
                setOpenSnackbar(true)
                navigate(-1)
            }
        })()
    }, [setAvatars, setTransition, setText, setSeverity, setOpenSnackbar, navigate])
    
    const handleChangePassword = () => {
        navigate('/change-password')
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setDisable(true)
        let data = new FormData(e.target)
        const email = data.get('email')
        const mobile = data.get('mobile')

        const updateProfile = await apiFetch(`${user.student ? user.student['@id'] : user.manager['@id']}`, {
            method: 'PUT',
            body: JSON.stringify({user: {'@id': user['@id'], email: email, mobile: mobile}})
        })
        if (updateProfile && updateProfile.uuid) {
            setOpenPhotoPopup(false)
            setSeverity('success')
            setText('Votre profil a bien été modifié !')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        } else {
            setSeverity('error')
            setText('Erreur lors de la modification du profil !')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        }
        setDisable(false)
    }

    const handlePhotoPopup = () => {
        setDisable(false)
        setOpenPhotoPopup(true)
    }

    const handleChoose = async () => {
        setDisable(true)
        let createPhoto = null
        let body = {}
        if (photo && (typeof photo === 'string' || photo instanceof String)) {
            body = {avatar: photo, photo: null}
        } else if (photo) {
            let dataPhoto = new FormData() 
            dataPhoto.append('file', photo && photo[0])
            createPhoto = await apiFetch('/photo_objects', {
                method: 'POST',
                body: dataPhoto,
            }, true)
            if (createPhoto && !createPhoto['hydra:description']) {
                body = {photo: createPhoto['@id'], avatar: null}
            } 
        }

        if (body.avatar || body.photo) {
            const updatePhoto = await apiFetch(`${user.student ? user.student['@id'] : user.manager['@id']}`, {
                method: 'PUT',
                body: JSON.stringify(body)
            })
            if (updatePhoto && updatePhoto.uuid) {
                setOpenPhotoPopup(false)
                setSeverity('success')
                setText('Votre photo a bien été modifiée !')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                window.location.reload()
            } else {
                setSeverity('error')
                setText('Erreur lors de la modification du profil !')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
            }
        } else {
            setSeverity('error')
            setText('Erreur lors de la modification du profil !')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        }
        setDisable(false)
    }

    const handleClose = () => {
        setPhoto(false)
        setOpenPhotoPopup(false)
    }

    const handleChange = () => {
        setDisable(false)
    }

    return (
        <Zoom in={zoom} timeout={700}>
            <Paper elevation={16} sx={{padding: 10}}>
                <Typography variant='h5' color='secondary' style={{fontWeight: 'bold'}}>Profil</Typography>
                {user.student && <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                        <CssIconButton onClick={handlePhotoPopup} ><ModeEditIcon /></CssIconButton>
                    }
                >
                    <Avatar src={user.student.photo.contentUrl} sx={{ml: 5, mt: 5, width: 110, height: 110}} />
                </Badge>}
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3} sx={{margin: 2}}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant='h6' color='secondary' sx={{mt: 3}}><span style={{color: 'gray'}}>Nom : </span>{user.lastName}</Typography>
                            <Typography variant='h6' color='secondary' sx={{mt: 5}}><span style={{color: 'gray'}}>Prénom : </span>{user.firstName}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField onChange={handleChange} color='secondary' label='Email' id='email' name='email' defaultValue={user.email} /><br />
                            {user.student && <TextField onChange={handleChange} color='secondary' label='Mobile' id='mobile' name='mobile' sx={{mt: 3}} type='tel' defaultValue={user.mobile} />}<br />
                            <Button variant='contained' color='primary' type='submit' disabled={disable} sx={{mt: 2}}>Enregistrer</Button>
                        </Grid>
                    </Grid>
                    <Button variant='contained' color='primary' sx={{ml: 5}} onClick={handleChangePassword}>Changer de mot de passe</Button>
                </form>
                <PhotoPopup avatars={avatars} disable={disable} open={openPhotoPopup} handleChoose={handleChoose} handleClose={handleClose} photo={photo} setPhoto={setPhoto} />
            </Paper>
        </Zoom>
    )
}