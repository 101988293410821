import { ThemeProvider, createTheme } from '@mui/material/styles';
import React, { useMemo, useEffect } from 'react'
import './App.css';
import { routes } from './service/routes/routes';
import MainMenu from './scenes/MainMenu';
import { Route, Routes } from 'react-router-dom'
import Error from './scenes/Error';
import Login from './scenes/HomeItem/Login';
import ValidateUser from './scenes/ValidateUser';
import { ResetPassword } from './scenes/ResetPassword';
import { HandleResetPassword } from './scenes/HandleResetPassword';
import ReactGA from 'react-ga';
import { NotRequireAuth } from './service/security/NotRequireAuth';
import { RequireAuth } from './service/security/RequireAuth';
const TRACKING_ID = "G-H782SVD82E";

ReactGA.initialize(TRACKING_ID);

function App() {
    const theme = useMemo(
        () => createTheme({
          palette: {
            primary: {
              light: '#4c31ac',
              main: '#ECA914',
              dark: '#B77E00',
              contrastText: '#fff',
            },
            secondary: {
              light: '#adffff',
              main: '#01304A',
              dark: '#074F77',
              contrastText: '#000',
            },
            background: {
              default: "#F8F8F8"
            }
          },
          typography: {
            fontFamily: [
              'Aileron Regular',
              'Arial',
              'sans-serif',
              '"Apple Color Emoji"',
              '"Segoe UI Emoji"',
              '"Segoe UI Symbol"',
            ].join(',')
          }
        }),
        [],
    )

    window.location.pathname !== '/' && window.location.pathname !== '/login' && window.location.pathname !== '/dashboard' && localStorage.setItem('LAST_URL', window.location.pathname)
    
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <div className="App" style={{margin: 0}}>
            <ThemeProvider theme={theme}>
              <Routes>
                <Route path={'*'} element={
                    <RequireAuth>
                      <MainMenu />
                    </RequireAuth>
                  }
                />
                <Route path={routes.resetPassword} element={
                    <NotRequireAuth>
                      <ResetPassword />
                    </NotRequireAuth>
                  }
                />
                <Route path={routes.login} element={
                    <NotRequireAuth>
                      <Login />
                    </NotRequireAuth>
                  }
                />
                <Route path={routes.resetPasswordId} element={
                    <NotRequireAuth>
                      <HandleResetPassword />
                    </NotRequireAuth>
                  }
                />
                <Route path={routes.validateUser} element={
                    <NotRequireAuth>
                      <ValidateUser />
                    </NotRequireAuth>
                  }
                />
                <Route path={'/error'} element={
                    <Error />
                  }
                />
              </Routes>
            </ThemeProvider>
        </div>
    );
}

export default App;
