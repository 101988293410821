import {
    Typography,
    Zoom,
    CircularProgress,
    Toolbar,
    Box,
    Table,
    TablePagination,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    Paper,
    TableContainer,
    TableBody,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TransitionLeft } from '../helper/utils'
import { apiFetch } from '../service/security/apiFetch'
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { ThemeProvider } from '@mui/styles'
import * as locales from '@mui/material/locale';
import { createTheme } from '@mui/material/styles'

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {id: 'label', numeric: false, disablePadding: false, label: 'Label'},
    {id: 'value', numeric: true, disablePadding: false, label: 'Valeur', },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{color: '#01304A', fontWeight: 'bold'}}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            <Typography
                sx={{ flex: '1 1 100%', color: '#ECA914' }}
                variant="h4"
                id="tableTitle"
                component="div"
            >
                Paramètres
            </Typography>
        </Toolbar>
    );
};

export default function Parameter({ zoom, setSeverity, setText, setTransition, setOpenSnackbar }) {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [page, setPage] = useState(0);
    const [parameter, setParameter] = useState(false)
    const [locale] = useState('frFR');
    let navigate = useNavigate()
    document.title = 'Wellmonday - Paramètres'

    useEffect(() => {
        (async () => {
            const fetchParameter = await apiFetch('/parameters')
            if (fetchParameter && fetchParameter['hydra:member']) {
                setParameter(fetchParameter['hydra:member'])
            } else {
                setSeverity('error')
                setText('Erreur lors du chargement des données')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                navigate(-1)
            }
        })()
    }, [setParameter, setSeverity, setText, setTransition, setOpenSnackbar, navigate])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Avoid a layout jump when reaching the last page with empty parameter.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * 30 - parameter.length) : 0;

    return (
        <Zoom in={zoom} timeout={700}>
            <Box sx={{ width: '100%' }}>
                <Paper elevation={16} sx={{ width: '100%', mb: 2 }}>
                    <EnhancedTableToolbar />
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                        >
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                                   parameter.slice().sort(getComparator(order, orderBy)) */}
                                {parameter ? stableSort(parameter, getComparator(order, orderBy))
                                    .slice(page * 30, page * 30 + 30)
                                    .map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={row['@id']}
                                            >
                                                <TableCell align="left">{row.label}</TableCell>
                                                <TableCell align="right">{`${row.value}`}</TableCell>
                                            </TableRow>
                                        );
                                }) : <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={3} />
                                    <TableCell>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales[locale])}>
                        {parameter && <TablePagination
                            labelRowsPerPage=''
                            rowsPerPageOptions={[]}
                            component="div"
                            count={parameter.length}
                            rowsPerPage={30}
                            page={page}
                            onPageChange={handleChangePage}
                        />}
                    </ThemeProvider>
                </Paper>
            </Box>
        </Zoom>
    );  
}