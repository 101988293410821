import { apiFetch } from "../service/security/apiFetch"
import { handleFormError } from "../service/security/error"
import { TransitionLeft } from "./utils"

export const handleCloseSignePopup = async ({ setCoachings, setTransition, setText, setSeverity, setOpenSnackbar, setOpenSignePopup}) => {
    setOpenSignePopup(false)
    const fetchCoachings = await apiFetch(`/coachings`)
    if (fetchCoachings && fetchCoachings['hydra:member']) {
        setCoachings(fetchCoachings['hydra:member'])
    } else {
        setTransition(() => TransitionLeft)
        setText('Erreur !')
        setSeverity('error')
        setOpenSnackbar(true)
    }
}

export const handleChangeCoach = async ({ setCoachings, setTransition, setText, setSeverity, setOpenSnackbar, setDisable, openConfirmationPopup, setOpenConfirmationPopup, setDashboardData }) => {
    setDisable(true)
    const changeCoach = await apiFetch(`/coachings/${openConfirmationPopup.coaching.uuid}/abort`)

    if (changeCoach && changeCoach.success) {
        if (openConfirmationPopup.updateState) {
            setTransition(() => TransitionLeft)
            setText('La demande de changement de coach a bien été envoyée !')
            setSeverity('success')
            setOpenSnackbar(true)
            setOpenConfirmationPopup(false)
            openConfirmationPopup.updateState.navigate('/dashboard')
        } else {
            const fetchCoachings = openConfirmationPopup.isFromDashboard ? await apiFetch(`/dashboard`) : await apiFetch(`/coachings`)
            if (fetchCoachings && (fetchCoachings['hydra:member'] || fetchCoachings.coachings)) {
                openConfirmationPopup.isFromDashboard ? setDashboardData(fetchCoachings) : setCoachings(fetchCoachings['hydra:member'])
                setTransition(() => TransitionLeft)
                setText('La demande de changement de coach a bien été envoyée !')
                setSeverity('success')
                setOpenSnackbar(true)
                setOpenConfirmationPopup(false)
            }
        }
    } else {
        setTransition(() => TransitionLeft)
        setText('Erreur lors de la demande de changement de coach !')
        setSeverity('error')
        setOpenSnackbar(true)
    }
    setDisable(false)
}

export const handleOpenManageExtensionPopup = (coaching, setSeancesSuggested, setOpenManageExtensionPopup, params) => {
    setSeancesSuggested(coaching.extensionRequest.suggestedNbSeances)
    setOpenManageExtensionPopup({coaching: coaching, params: params})
}

export const handleDeclineExtension = async ({ setOpenManageExtensionPopup, setCoachings, setTransition, setText, setSeverity, setOpenSnackbar, setDisable, openManageExtensionPopup}) => {
    setDisable(true)
    const decline = await apiFetch(`${openManageExtensionPopup.coaching.extensionRequest['@id']}`, {
        method: 'PUT',
        body: JSON.stringify({
            status: 'refused',
        })
    })
    if (decline && decline.status) {
        const fetchCoachings = await apiFetch(`/coachings?session.uuid=${openManageExtensionPopup.params.id}`)
        if (fetchCoachings && fetchCoachings['hydra:member']) {
            setCoachings(fetchCoachings['hydra:member'])
            setTransition(() => TransitionLeft)
            setText('La demande de prolongation a bien été refusée ! Le coaching est maintenant Clôturé !')
            setSeverity('success')
            setOpenSnackbar(true)
            setOpenManageExtensionPopup(false)
        }
    } else {
        setTransition(() => TransitionLeft)
        setText('Erreur lors du refus de la demande !')
        setSeverity('error')
        setOpenSnackbar(true)
    }
    setDisable(false)
}

export const handleAcceptExtension = async ({ seancesSuggested, setOpenManageExtensionPopup, setCoachings, setTransition, setText, setSeverity, setOpenSnackbar, setDisable, openManageExtensionPopup}) => {
    setDisable(true)
    const accept = await apiFetch(`${openManageExtensionPopup.coaching.extensionRequest['@id']}`, {
        method: 'PUT',
        body: JSON.stringify({
            status: 'accepted',
            validatedNbSeances: seancesSuggested,
        })
    })
    if (accept && accept.status) {
        const fetchCoachings = await apiFetch(`/coachings?session.uuid=${openManageExtensionPopup.params.id}`)
        if (fetchCoachings && fetchCoachings['hydra:member']) {
            setCoachings(fetchCoachings['hydra:member'])
            setTransition(() => TransitionLeft)
            setText('La demande de prolongation a bien été validée !')
            setSeverity('success')
            setOpenSnackbar(true)
            setOpenManageExtensionPopup(false)
        }
    } else {
        setTransition(() => TransitionLeft)
        setText('Erreur lors de la validation de la demande !')
        setSeverity('error')
        setOpenSnackbar(true)
    }
    setDisable(false)
}

export const handleContractPopup = ({coaching, setOpenContractPopup, isFromDashboard, updateState }) => {
    if (coaching.objective) {
        handleAddObjectives(true, {coaching: coaching})
    } else {
        setOpenContractPopup({coaching: coaching, isFromDashboard: isFromDashboard, updateState: updateState})
    }
}

export const handleSignePopup = async ({coaching, setOpenBackdrop, setOpenSignePopup, setTransition, setText, setSeverity, setOpenSnackbar}) => {
    setOpenBackdrop(true)
    const getContract = await apiFetch(coaching.contractSigningSessionUrl)

    if (getContract && getContract.sessionUrl) {
        setOpenSignePopup(getContract)
    } else {
        setTransition(() => TransitionLeft)
        setText('Impossible de signer le contrat pour le moment, veuillez réessayer plus tard !')
        setSeverity('error')
        setOpenSnackbar(true)
    }
    setOpenBackdrop(false)
}

export const handleAddObjectives = async (skipObjective, {setCoachings, navigate, setContent, setOpenContractPopup, coaching, setTransition, setText, setSeverity, setOpenSnackbar, setDisable, setError, openContractPopup, content, setDashboardData, isFromDashboard}) => {
    setDisable(true)
    setError({})

    let addObjectives = null
    if (skipObjective.target) {
        addObjectives = await apiFetch('/coaching_objectives', {
            method: 'POST',
            body: JSON.stringify({
                coaching: openContractPopup.coaching['@id'],
                content: content,
            })
        })
    } else {
        addObjectives = coaching
    }


    if (addObjectives && addObjectives.uuid) {
        const generateContract = await apiFetch('/contracts', {
            method: 'POST',
            body: JSON.stringify({
                coaching: addObjectives.coaching || `${coaching['@id']}`
            })
        })
        if (generateContract && generateContract.reference) {
            if (openContractPopup.updateState) {
                const fetchCoaching = await apiFetch(`/coachings/${openContractPopup.updateState.params.id}`)
                if (fetchCoaching && fetchCoaching.uuid) {
                    openContractPopup.updateState.dispatch(fetchCoaching)
                    setTransition(() => TransitionLeft)
                    setText('La demande de changement de coach a bien été envoyée !')
                    setSeverity('success')
                    setOpenSnackbar(true)
                    setOpenContractPopup(false)
                }
            } else {
                const fetchCoachings = openContractPopup.isFromDashboard ? await apiFetch('/dashboard') : await apiFetch('/coachings')
                if (fetchCoachings && (fetchCoachings['hydra:member'] || fetchCoachings.coachings)) {
                    openContractPopup.isFromDashboard ? setDashboardData(fetchCoachings) : setCoachings(fetchCoachings['hydra:member'])
                    setTransition(() => TransitionLeft)
                    setText('Le contrat a bien été généré !')
                    setSeverity('success')
                    setOpenSnackbar(true)
                    setContent('')
                    setOpenContractPopup(false)
                } else {
                    setTransition(() => TransitionLeft)
                    setText('Erreur lors du chargement des données')
                    setSeverity('error')
                    setOpenSnackbar(true)
                    navigate('/')
                }
            }
        } else {
            setTransition(() => TransitionLeft)
            setText('Impossible de générer le contrat, veuillez réessayer')
            setSeverity('error')
            setOpenSnackbar(true)
        }
    } else {
        const formError = handleFormError(addObjectives, ['content'])
        setError(formError)
        setTransition(() => TransitionLeft)
        setText('Erreur, veuillez réessayer')
        setSeverity('error')
        setOpenSnackbar(true)
    }
    setDisable(false)
}

export const handleCloseCoaching = async ({setCoachings, navigate, openConfirmationPopup, setOpenConfirmationPopup, setTransition, setText, setSeverity, setOpenSnackbar, setDisable, setDashboardData}) => {
    setDisable(true)
    const close = await apiFetch(`/coachings/${openConfirmationPopup.coaching.uuid}/close`)
    if (close.message) {
        setTransition(() => TransitionLeft)
        setText(close.message)
        setSeverity('error')
        setOpenSnackbar(true)
    } else {
        if (openConfirmationPopup.updateState) {
            const fetchCoaching = await apiFetch(`/coachings/${openConfirmationPopup.updateState.params.id}`)
            if (fetchCoaching && fetchCoaching.uuid) {
                openConfirmationPopup.updateState.dispatch(fetchCoaching)
                setTransition(() => TransitionLeft)
                setText('Le coaching a bien été clôturé !')
                setSeverity('success')
                setOpenSnackbar(true)
                setOpenConfirmationPopup(false)
            } else {
                setTransition(() => TransitionLeft)
                setText('Erreur lors du chargement des données')
                setSeverity('error')
                setOpenSnackbar(true)
                navigate('/')
            }
        } else {
            const fetchCoachings = openConfirmationPopup.isFromDashboard ? await apiFetch('/dashboard') : await apiFetch('/coachings')
            if (fetchCoachings && (fetchCoachings['hydra:member'] || fetchCoachings.coachings)) {
                openConfirmationPopup.isFromDashboard ? setDashboardData(fetchCoachings) : setCoachings(fetchCoachings['hydra:member'])
                setTransition(() => TransitionLeft)
                setText('Le coaching a bien été clôturé !')
                setSeverity('success')
                setOpenSnackbar(true)
                setOpenConfirmationPopup(false)
            } else {
                setTransition(() => TransitionLeft)
                setText('Erreur lors du chargement des données')
                setSeverity('error')
                setOpenSnackbar(true)
                navigate('/')
            }
        }
    }
    setDisable(false)
}

export const handleAddExtension = async ({openExtensionPopup, setOpenExtensionPopup, setCoachings, setError, seancesSuggested, reason, setTransition, setText, setSeverity, setOpenSnackbar, setDisable, setDashboardData }) => {
    setDisable(true)
    setError({})
    const extension = await apiFetch(`/coaching_extension_requests`, {
        method: 'POST',
        body: JSON.stringify({
            suggestedNbSeances: parseInt(seancesSuggested) || 0,
            reason: reason,
            coaching: openExtensionPopup.coaching['@id']
        })
    })

    if (extension && extension.suggestedNbSeances) {
        if (openExtensionPopup && openExtensionPopup.updateState) {
            const fetchCoaching = await apiFetch(`/coachings/${openExtensionPopup.updateState.params.id}`)
            if (fetchCoaching && fetchCoaching.uuid) {
                openExtensionPopup.updateState.dispatch(fetchCoaching)
                setTransition(() => TransitionLeft)
                setText('Votre demande a bien été envoyée et sera prise en compte dans les plus brefs delais !')
                setSeverity('success')
                setOpenSnackbar(true)
                setOpenExtensionPopup(false)
            }
        } else {
            const fetchCoachings = openExtensionPopup.isFromDashboard ? await apiFetch('/dashboard') : await apiFetch('/coachings')
            if (fetchCoachings && (fetchCoachings['hydra:member'] || fetchCoachings.coachings)) {
                openExtensionPopup.isFromDashboard ? setDashboardData(fetchCoachings) : setCoachings(fetchCoachings['hydra:member'])
                setTransition(() => TransitionLeft)
                setText('Votre demande a bien été envoyée et sera prise en compte dans les plus brefs delais !')
                setSeverity('success')
                setOpenSnackbar(true)
                setOpenExtensionPopup(false)
            }
        }
    } else {
        const formError = handleFormError(extension, ['suggestedNbSeances', 'reason'])
        setError(formError)
        setTransition(() => TransitionLeft)
        setText('Erreur lors de la demande de prolongation !')
        setSeverity('error')
        setOpenSnackbar(true)
    }
    setDisable(false)
}

export const handleOpenConfirmationPopup = ({coaching, setOpenConfirmationPopup, isFromDashboard, updateState}) => {
    setOpenConfirmationPopup({coaching: coaching, isFromDashboard: isFromDashboard, updateState: updateState})
}

export const handleCloseConfirmationPopup = ({setOpenConfirmationPopup}) => {
    setOpenConfirmationPopup(false)
}