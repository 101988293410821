import React, { useEffect, useState } from 'react'
import frLocale from 'date-fns/locale/fr';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
    Zoom,
    TextField,
    Grid,
    Avatar,
    Button,
    Typography,
    CircularProgress,
} from '@mui/material'
import { Paper } from '@mui/material';
import { apiFetch } from '../service/security/apiFetch';
import { TransitionLeft } from '../helper/utils';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

export default function MakeAppointment({ zoom, setOpenSnackbar, setText, setTransition, setSeverity, isFromPopup, handleClose }) {
    const [value, setValue] = React.useState(false);
    const [dashboardData, setDashboardData] = useState(false)
    const [availabilities, setAvailabilities] = useState(false)
    const [loading, setLoading] = useState(false)
    const [availability, setAvailability] = useState(false)
    const [disable, setDisable] = useState(true)
    const [target, setTarget] = useState(false)
    const [hour, setHour] = useState(false)
    let navigate = useNavigate()
    document.title = 'Wellmonday - Prendre un RDV'

    useEffect(() => {
        (async () => {
            setLoading(true)
            const fetchDashboardData = await apiFetch('/dashboard')
            if (fetchDashboardData) {
                setDashboardData(fetchDashboardData)
            }
            const fetchAvailabilities = await apiFetch(`/availabilities?coach.uuid=${fetchDashboardData.currentCoaching.coach.uuid}&pagination=false`)
            if (fetchAvailabilities && fetchAvailabilities['hydra:member']) {
                setAvailabilities(fetchAvailabilities['hydra:member'])
                setValue(new Date(fetchAvailabilities['hydra:member'][0] && fetchAvailabilities['hydra:member'][0].startAt))
                const findHour = fetchAvailabilities['hydra:member'].filter((i) => moment(i.startAt).format('YYYY-MM-DD') === moment(new Date(fetchAvailabilities['hydra:member'][0].startAt)).format('YYYY-MM-DD'))
                setHour(findHour)
            } else {
                setText((fetchAvailabilities && fetchAvailabilities['hydra:description']) || 'Erreur lors de la récupération des données')
                setSeverity('error')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
            }
            setLoading(false)
        })()
    }, [setText, setSeverity, setTransition, setOpenSnackbar, setHour])
    
    const handleDisableDate = (e) => {
        let hasDate = false
        availabilities && availabilities.map((i) => {
            if(moment(i.startAt).format('YYYY-MM-DD') === moment(e).format('YYYY-MM-DD')) {
                hasDate = true
            }
            return null
        })
        return !hasDate
    }

    const handleActive = (e, el) => {
        setTarget(e.target.id)
        setAvailability(el)
        e.target.style.backgroundColor = '#ECA914'
        e.target.style.color = 'white'
        if (target) {
            const el = document.getElementById(target)
            el.style.backgroundColor = '#e0e0e0'
            el.style.color = 'black'
        }
        setDisable(false)
    }

    const handleMakeAppointment = async () => {
        setDisable(true)
        const postSeance = await apiFetch(`/seances`, {
            method: 'POST',
            body: JSON.stringify({
                coaching: `/coachings/${dashboardData.currentCoaching.uuid}`,
                availability: `/availabilities/${availability.uuid}`,
            })
        })

        if (postSeance && !postSeance['hydra:description']) {
            setText('Votre RDV a bien été enregistré !')
            setSeverity('success')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
            navigate('/')
        } else {
            setText((postSeance && postSeance['hydra:description']) || 'Erreur lors de la prise de RDV !')
            setSeverity('error')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        }
        setDisable(false)
    }
    
    return (
        <Zoom in={zoom} timeout={700}>
            <div>
                {dashboardData ? <>
                    {!isFromPopup && <Paper elevation={16} sx={{ mb: 4, p: 5, textAlign: 'center'}}>
                        <Typography variant='h4' color='secondary'> Planifiez votre séance avec {dashboardData.currentCoaching.coach.user.firstName} {dashboardData.currentCoaching.coach.user.lastName}</Typography>
                    </Paper>}
                    <Grid container spacing={3} sx={{textAlign: 'center'}}>
                        {!isFromPopup && <Grid item xs={12} sm={12} md={12} lg={4}>
                            <Paper elevation={16} sx={{ p: 5}}>
                                <Avatar sx={{width: 100, height: 100, margin: 'auto'}} src={dashboardData.currentCoaching.coach.photo.contentUrl} />
                                <Typography variant='h6' color='secondary'> {dashboardData.currentCoaching.coach.user.firstName} {dashboardData.currentCoaching.coach.user.lastName}</Typography>
                                <div style={{textAlign: 'left', marginTop: 20}}>
                                    <Typography variant='body1' color='secondary'>Nombre de séances : {dashboardData.currentCoaching.nbSeances}</Typography>
                                    <Typography variant='body1' color='secondary'>Nombre de séance(s) restante(s) : {dashboardData.currentCoaching.nbSeancesLeft}</Typography>
                                </div>
                            </Paper>
                        </Grid>}
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            <Paper elevation={16} sx={{ display: 'flex'}}>
                                {!value && <><div style={{textAlign: 'center', width: '100%', pading: 20}}><CircularProgress /></div><br /></>}
                                <div style={{marginTop: 10, marginLeft: 30}}>
                                    {value && availabilities && availabilities.length ? <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
                                        <StaticDatePicker
                                            displayStaticWrapperAs="desktop"
                                            openTo="day"
                                            value={value}
                                            shouldDisableDate={handleDisableDate}
                                            onChange={(newValue) => {
                                                const findHour = availabilities && availabilities.filter((i) => moment(i.startAt).format('YYYY-MM-DD') === moment(newValue).format('YYYY-MM-DD'))
                                                setHour(findHour)
                                                setValue(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider> : !loading && <div style={{textAlign: 'left'}}>
                                        <Typography variant='h6' style={{width: isFromPopup ? 300 : 400, marginBottom: 10, marginTop: 10}}>Pas de disponibilité pour le moment... Vous pouvez contacter votre coach via la messagerie de votre espace.</Typography>
                                        <Button onClick={() => navigate('/messages')} variant='contained' style={{width: 250, marginBottom: 30}}>Messagerie</Button>
                                    </div >}
                                </div>
                                {hour && <Grid container spacing={0} sx={{mt: 3, ml: {xl: 1, lg: 4, md: 3, sm: 8, xs: 3}, mr: {xl: 1, lg: 4, md: 3, sm: 8, xs: 3}}}>
                                    {hour.map((i, index) => (
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={5} key={index}>
                                            <Button id={index} variant='contained' color={'inherit'} sx={{width: 150, height: 50}} onClick={(e) => handleActive(e, i)}>{moment(i.startAt).format('HH:mm')}</Button>
                                        </Grid>
                                    ))}
                                </Grid>}
                            </Paper>
                        </Grid>
                        <div style={{width: '100%'}}>
                            <Button onClick={isFromPopup ? (e) => handleClose(e, {isPopup: true, isEndOfVisio: false}) : () => navigate('/')} variant='contained' sx={{margin: 'auto', mt: 4, width: 180, height: 60, fontSize: 22, mr: 2}}>retour</Button>
                            <Button onClick={handleMakeAppointment} variant='contained' sx={{margin: 'auto', mt: 4, width: 180, height: 60, fontSize: 22}} disabled={disable}>Valider</Button>
                        </div>
                    </Grid>
                </> : 
                <div style={{textAlign: 'center'}}><CircularProgress /></div>}
            </div>
        </Zoom>
    )
}