import React, { useEffect, useState } from 'react'
import {
    Typography,
    Zoom,
    CircularProgress,
    Button,
    IconButton,
    Avatar,
    Toolbar,
    Box,
    Table,
    TablePagination,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    Paper,
    TableContainer,
    TableBody,
    Grid,
    MobileStepper,
    Tooltip,
    ButtonGroup,
} from '@mui/material'
import moment from 'moment'
import { useNavigate, useParams } from 'react-router-dom';
import { apiFetch } from '../../../service/security/apiFetch';
import { download, getArrayOfSeanceEnded, shouldShowChangeCoachButton, shouldShowCloseButton, shouldShowContractsButton, shouldShowSignedButton, StyledTooltip, TransitionLeft, translateStatus } from '../../../helper/utils';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FollowUpPopup from '../FollowUpPopup';
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { ThemeProvider } from '@mui/styles'
import * as locales from '@mui/material/locale';
import { createTheme } from '@mui/material/styles'
import TvIcon from '@mui/icons-material/Tv';
import { hasRoles } from '../../../service/security/hasRoles';
import { handleContractPopup, handleOpenConfirmationPopup,  handleSignePopup } from '../../../helper/CoachingActions';
import DeleteIcon from '@mui/icons-material/Delete';
import DeletedConfirmationPopup from '../../../components/DeletedConfirmationPopup';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {id: 'seances', numeric: false, disablePadding: false, label: 'Séances'},
    {id: 'date', numeric: true, disablePadding: false, label: 'Date', },
    {id: 'duration', numeric: true, disablePadding: false, label: 'Durée', },
    {id: 'suivi', numeric: true, disablePadding: false, label: 'Suivi', },
    {id: 'status', numeric: true, disablePadding: false, label: 'Statut', },
    {id: 'visio', numeric: true, disablePadding: false, label: 'Accéder à la visio', },
];

const adminHeadCells = [
    {id: 'seances', numeric: false, disablePadding: false, label: 'Séances'},
    {id: 'date', numeric: true, disablePadding: false, label: 'Date', },
    {id: 'duration', numeric: true, disablePadding: false, label: 'Durée', },
    {id: 'status', numeric: true, disablePadding: false, label: 'Statut', },
    {id: 'action', numeric: true, disablePadding: false, label: 'Action', },
]

function findHeadCells() {
    if (hasRoles() === 'admin') {
        return adminHeadCells
    }
    return headCells
}

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {findHeadCells().map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{color: '#01304A', fontWeight: 'bold'}}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            <Typography
                sx={{ flex: '1 1 100%', color: '#ECA914' }}
                variant="h4"
                id="tableTitle"
                component="div"
            >
                Coaching
            </Typography>
        </Toolbar>
    );
};

export default function CoachCoachingDetail({ zoom, setOpenSnackbar, setText, setTransition, setSeverity, setOpenExtensionPopup, setOpenConfirmationPopup, setOpenBackdrop, setSeancesSuggested, setOpenManageExtensionPopup, setOpenSignePopup, setOpenContractPopup }) {
    const [coaching, setCoaching] = useState(false)
    const [openPopup, setOpenPopup] = useState(false)
    const [followUpPopup, setFollowUpPopup] = useState(true)
    const [openDeletePopup, setOpenDeletePopup] = useState(false)
    const [locale] = useState('frFR');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [disable, setDisable] = useState(false)
    const [page, setPage] = useState(0);
    const params = useParams();

    let navigate = useNavigate()

    const handleOpenPopup = (props) => {
        setOpenPopup({props, coaching})
    }

    useEffect(() => {
        (async () => {
            if (!coaching) {
                const fetchCoaching = await apiFetch(`/coachings/${params.id}`)
                if (fetchCoaching && fetchCoaching.uuid) {
                    setCoaching(fetchCoaching)
                } else {
                    setSeverity('error')
                    setText('Erreur lors du chargement des données')
                    setTransition(() => TransitionLeft)
                    setOpenSnackbar(true)
                    navigate(-1)
                }
            }
        })()
    }, [params, navigate, setSeverity, setText, setTransition, setOpenSnackbar, coaching])

    const handleRefresh = async () => {
        setFollowUpPopup(false)
        const fetchCoaching = await apiFetch(`/coachings/${params.id}`)
        if (fetchCoaching && fetchCoaching.uuid) {
            setCoaching(fetchCoaching)
        } else {
            setSeverity('error')
            setText('Erreur lors du chargement des données')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
            navigate(-1)
        }
        setFollowUpPopup(true)
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleClose = () => {
        setFollowUpPopup(false)
        setOpenPopup(false)
        setTimeout(() => {
            setFollowUpPopup(true)
        }, 50)
    }

    // Avoid a layout jump when reaching the last page with empty coaching.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * 30 - coaching.length) : 0;

    const handleVisio = (row) => {
        window.open(row.visioUrl, "_blank")
    }

    const handleDownload = async (document) => {
        setDisable(true)
        const amazonLink = await apiFetch(document.downloadUrl, {}, false, false, false, false, true)
        
        if (amazonLink && amazonLink.signedUrl) {
            const aws3Doc = await apiFetch(`${amazonLink.signedUrl}`, {method: 'GET'}, true, true, true)
            if (aws3Doc.ok) {
                const blob = await aws3Doc.blob()
                download(blob, `${document.name} - ${moment(document.createdAt).format('DD MMMM YYYY')}`)
            }
        }
        setDisable(false)
    }

    const handleBack = () => {
        navigate(-1)
    }

    const handleDeletePopup = (row) => {
        setOpenDeletePopup(row)
    }

    const handleDelete = async () => {
        const deleteSeances = await apiFetch(`/seances/${openDeletePopup.uuid}`, {method: 'DELETE'})
        if (deleteSeances && !deleteSeances['hydra:description']) {
            const fetchCoaching = await apiFetch(`/coachings/${params.id}`)
            if (fetchCoaching && fetchCoaching.uuid) {
                setCoaching(fetchCoaching)
                setSeverity('success')
                setText('La séance a été supprimée')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                setOpenDeletePopup(false)
            } else {
                setSeverity('error')
                setText('Erreur lors de la suppression')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
            }
        } else {
            setSeverity('error')
            setText((deleteSeances && deleteSeances['hydra:description']) || 'Erreur lors de la suppression')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        }
    }

    return (
        <>
            {coaching && <Toolbar>
                <div style={{flex: '1 1 100%'}}>
                    <Button onClick={handleBack} variant='contained' color='primary' sx={{mb: 5}}>Retour</Button>
                </div>
                <ButtonGroup variant='contained' color='secondary' sx={{color: 'white', mb: 5}}>
                    {shouldShowCloseButton(coaching) && <Button disabled={disable} sx={{color: 'white'}} onClick={() => setOpenExtensionPopup({coaching, updateState: {dispatch: setCoaching, params: params}})}>
                        Prolonger
                    </Button>}
                    {shouldShowCloseButton(coaching) && <Button disabled={disable} sx={{color: 'white'}} onClick={() => handleOpenConfirmationPopup({coaching, setOpenConfirmationPopup, updateState: {dispatch: setCoaching, params: params}})}>
                        Clôturer
                    </Button>}
                </ButtonGroup>
                <ButtonGroup variant='contained' color='secondary' sx={{color: 'white', mb: 5}}>
                    {shouldShowSignedButton(coaching) ? <Button disabled={disable} variant='contained' color='secondary' sx={{color: 'white'}} onClick={() => handleSignePopup({coaching, setOpenBackdrop, setOpenSignePopup, setTransition, setText, setSeverity, setOpenSnackbar})}>Signer le contrat de coaching</Button> :
                    shouldShowContractsButton(coaching) && <Button disabled={disable} variant='contained' color='secondary' sx={{color: 'white'}} onClick={() => handleContractPopup({coaching, setOpenContractPopup, updateState: {dispatch: setCoaching, params: params}})}>Générer le contrat de coaching</Button>}
                    {shouldShowChangeCoachButton(coaching) && <Button disabled={disable} variant='contained' color='secondary' sx={{color: 'white'}} onClick={() => handleOpenConfirmationPopup({coaching, setOpenConfirmationPopup, updateState: {navigate: navigate, params: params}})}>Changer de coach</Button>}
                </ButtonGroup>
            </Toolbar>}
            {coaching &&
                <> 
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                            <Zoom in={zoom} timeout={700}>
                                <Paper elevation={16} sx={{ pb: 5.3}}>
                                    <Grid container spacing={3}>
                                        <Grid item lg={12} xl={6}>
                                            <Avatar src={coaching.student.photo && coaching.student.photo.contentUrl} sx={{width: 100, ml: 5, height: 100}} />
                                        </Grid>
                                        <Grid item lg={12} xl={6}>
                                            <Typography variant='h6' sx={{fontWeight: 'bold', ml: {xl: 0, lg: 3, md: 3, sm: 3, xs: 3}, mt: {xl: 0, lg: -2, md: -2, sm: -2, xs: -2}}} color='primary'>{coaching.student.user && coaching.student.user.firstName} {coaching.student.user && coaching.student.user.lastName}</Typography><br />
                                            <Typography variant='body1' sx={{ml: {xl: 0, lg: 3, md: 3, sm: 3, xs: 3}, mt: {xl: 0, lg: -4, md: -4, sm: -4, xs: -4}}}>{coaching.student.company && coaching.student.company.name}</Typography>
                                            <Typography variant='body1' sx={{ml: {xl: 0, lg: 3, md: 3, sm: 3, xs: 3}, mt: {xl: 0, lg: 0, md: 0, sm: 0, xs: 0}}}>{coaching.student.user.mobile && coaching.student.user.mobile}</Typography>
                                        </Grid>
                                    </Grid>
                                    {coaching.softSkill && <Typography variant='body2' sx={{ml: 3, mt: 1}}><span style={{fontStyle: 'italic'}}>Objectif :</span> {coaching.softSkill.name}</Typography>}
                                    {coaching.expectation && <Typography variant='body2' sx={{ml: 3, mt: 1, mr: 1}}><span style={{fontStyle: 'italic'}}>Motivation :</span> {coaching.expectation}</Typography>}
                                    {hasRoles() === 'admin' && coaching.coach && <Typography variant='body2' sx={{ml: 3, mt: 1.5}}><span style={{fontStyle: 'italic'}}>Coach :</span> {coaching.coach.user.firstName} {coaching.coach.user.lastName}</Typography>}
                                </Paper>
                            </Zoom> 
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Zoom in={zoom} timeout={700} style={{ transitionDelay: '800ms' }}>
                                <Paper elevation={16} sx={{ pl: 3, pb: 2, marginTop: {xl: 0, lg: 0, md: 0, sm: 0, xs: 2}}}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={4}>
                                            {hasRoles() === 'admin' && <Typography variant='body2'><span style={{fontStyle: 'italic'}}>Statut : </span>{translateStatus(coaching.status)}</Typography>}
                                        </Grid>
                                    </Grid>
                                    <Typography variant='h6' sx={{mt: 3, mb: 2}}>Documents</Typography>
                                    {coaching.documents && !!coaching.documents.length && <Grid container spacing={3} sx={{mb: 2}}>
                                        {coaching.documents.map(document => {
                                            return <Grid item xs={12} sm={4} key={document.id}>
                                                <Typography variant='body1'>{document.name} <IconButton size="small" disabled={disable} style={{color: '#ECA914', cursor: 'pointer'}} onClick={() => handleDownload(document)}><AttachFileIcon /></IconButton></Typography>
                                                <Typography variant='caption' sx={{color: 'gray'}}>{moment(document.createdAt).format('DD/MM/yyyy')}</Typography>
                                            </Grid>
                                        })}
                                    </Grid>}
                                </Paper>
                            </Zoom>
                        </Grid>
                    </Grid>
                    <Zoom in={zoom} timeout={800} style={{ transitionDelay: '400ms' }}>
                        <Box sx={{ width: '100%', mt: 6, }}>
                            <Paper elevation={16} sx={{ width: '100%', mb: 2,  }}>
                                <Grid container spacing={3} sx={{paddingLeft: 2}}>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant='body1'>{((getArrayOfSeanceEnded(coaching.seances) && getArrayOfSeanceEnded(coaching.seances).length)) || 0 } séance(s) réalisée sur {coaching.nbSeances}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MobileStepper
                                            variant="progress"
                                            steps={coaching.nbSeances + 1}
                                            position="static"
                                            activeStep={((getArrayOfSeanceEnded(coaching.seances) && getArrayOfSeanceEnded(coaching.seances).length)) || 0}
                                            sx={{ maxWidth: 600, mt: 1, flexGrow: 1, backgroundColor: '#fff' }}
                                            LinearProgressProps={{style: {width: '100%', height: '7px'}}}
                                        />
                                    </Grid>
                                </Grid>
                                <EnhancedTableToolbar />
                                <TableContainer>
                                    <Table
                                        sx={{ minWidth: 750 }}
                                        aria-labelledby="tableTitle"
                                        size={'medium'}
                                    >
                                        <EnhancedTableHead
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={handleRequestSort}
                                        />
                                        <TableBody>
                                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                                               coaching.slice().sort(getComparator(order, orderBy)) */}
                                            {coaching ? stableSort(coaching.seances, getComparator(order, orderBy))
                                                .slice(page * 30, page * 30 + 30)
                                                .map((row, index) => {
                                                    const labelId = `enhanced-table-${index}`;
                                                
                                                    return (
                                                        <TableRow
                                                            hover
                                                            tabIndex={-1}
                                                            key={row.uuid}
                                                        >
                                                            <TableCell
                                                                component="th"
                                                                id={labelId}
                                                                scope="row"
                                                                padding="normal"
                                                            >
                                                                Séance {index + 1}
                                                            </TableCell>
                                                            <TableCell align="right">{moment(row.availability.startAt).format('DD/MM/YYYY à HH:mm')}</TableCell>
                                                            <TableCell align="right">{row.duration} min</TableCell>
                                                            {hasRoles() === 'coach' && <TableCell align="right"><StyledTooltip title={row.status === 'ended' ? row.noteSeance ? '' : 'Vous pouvez ajouter une note à la séance' : 'Vous devez attendre la fin de la séance pour ajouter une note'}><span><Button variant='contained' color='secondary' style={{color: 'white'}} disabled={row.status !== 'ended'} onClick={() => handleOpenPopup(row)}>{row.noteSeance ? 'Voir' : 'Modifier'}</Button></span></StyledTooltip></TableCell>}
                                                            <TableCell align="right">{translateStatus(row.status)}</TableCell>
                                                            {hasRoles() === 'coach' && <TableCell align="right">{coaching.nextSeance && coaching.nextSeance.uuid === row.uuid && coaching.nextSeance.visioUrl ? <IconButton onClick={() => handleVisio(row)}><TvIcon /></IconButton> : coaching.nextSeance && coaching.nextSeance.uuid === row.uuid && <Tooltip title='Vous pouvez rejoindre la visio à partir de 5 min avant le début de la séance'><span><IconButton disabled={true}><TvIcon /></IconButton></span></Tooltip>}</TableCell>}
                                                            {hasRoles() === 'admin' && <TableCell align="right">{coaching.nextSeance && coaching.nextSeance.uuid === row.uuid && <Tooltip title='Supprimer la séance !'><span><IconButton onClick={() => handleDeletePopup(row)}><DeleteIcon /></IconButton></span></Tooltip>}</TableCell>}
                                                        </TableRow>
                                                    );
                                            }) : <TableRow
                                                style={{
                                                    height: (53) * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={3} />
                                                <TableCell>
                                                    <CircularProgress />
                                                </TableCell>
                                            </TableRow>}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales[locale])}>
                                    {coaching && <TablePagination
                                        labelRowsPerPage=''
                                        rowsPerPageOptions={[]}
                                        component="div"
                                        count={coaching.seances.length}
                                        rowsPerPage={30}
                                        page={page}
                                        onPageChange={handleChangePage}
                                    />}
                                </ThemeProvider>
                            </Paper>
                            {followUpPopup && <FollowUpPopup handleRefresh={handleRefresh} open={openPopup} handleClose={handleClose} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} />}
                            <DeletedConfirmationPopup handleAccept={handleDelete} open={openDeletePopup} handleClose={() => setOpenDeletePopup(false)} />
                        </Box>
                    </Zoom>
                </>
            }
        </>
    )
}