import React from 'react'
import {
    Zoom,
    Paper,
    Typography,
    Grid,
    MobileStepper,
    Button,
    ButtonGroup,
    Tooltip,
} from '@mui/material/';
import moment from 'moment'
import { useNavigate } from 'react-router-dom';
import { getArrayOfSeanceEnded, shouldShowChangeCoachButton, shouldShowCloseButton, shouldShowContractsButton, shouldShowSignedButton, shouldShowVisionButton } from '../../../helper/utils';
import { handleContractPopup, handleOpenConfirmationPopup, handleSignePopup } from '../../../helper/CoachingActions';

export default function CurrentCoaching({ zoom, coachings, disable, setOpenExtensionPopup, setOpenConfirmationPopup, setOpenContractPopup, setTransition, setText, setSeverity, setOpenBackdrop, setOpenSnackbar, setOpenSignePopup }) {
    let navigate = useNavigate()

    const handleClick = (id) => {
        navigate(`/coaching/${id}/information`)
    }

    const handleVisio = (row) => {
        window.open(row.visioUrl, "_blank")
    }

    return (
            <div style={{marginTop: 50}}>
                <Typography variant='h5' sx={{fontWeight: 'bold'}} color='secondary'>MES COACHINGS EN COURS</Typography>
                {coachings.length ? coachings.map((coaching, index) => {
                    return (
                        <Zoom in={zoom} timeout={700} key={index}  style={{ transitionDelay: index * 100 + 200 + 'ms' }}>
                            <Paper elevation={16} sx={{ mt: 3, padding: 4}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={3}>
                                        <Typography variant='h6' color='primary'>{coaching.student.user.firstName} {coaching.student.user.lastName}</Typography>
                                        {coaching.seances && <div style={{marginTop: 10, color: '#707070'}}>
                                            <span>Prochaine séance : </span><br />
                                            <span>Début : </span>
                                        </div>}
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Typography variant='h6'>{coaching.student.company.name.toUpperCase()}</Typography>
                                        <div style={{marginTop: 10}}>
                                            <Typography variant='body1'>{coaching.seances && coaching.seances.length ? moment(coaching.seances[0].availability.startAt).format('DD MMMM YYYY à HH:mm') : 'Pas de prochaine séance'}</Typography>
                                            <Typography variant='body1'>{moment(coaching.createdAt).format('DD MMMM yyyy')}</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Typography variant='h6'>{((getArrayOfSeanceEnded(coaching.seances) && getArrayOfSeanceEnded(coaching.seances).length)) || 0 } séance(s) réalisée sur {coaching.nbSeances}</Typography>
                                        <ButtonGroup variant='contained' color='secondary' sx={{mt: 4}}>
                                            <Button variant='contained' color='primary' onClick={() => handleClick(coaching.uuid)}>Voir le coaching</Button>
                                            {shouldShowVisionButton(coaching) && <Tooltip arrow title={!coaching.nextSeance.visioUrl ? 'Vous pouvez rejoindre la visio à partir de 5 min avant le début de la séance' : ''}><span><Button disabled={!coaching.nextSeance.visioUrl} variant='contained' color='secondary' sx={{color: 'white'}} onClick={() => handleVisio(coaching.nextSeance)}>Rejoindre la Visio</Button></span></Tooltip>}
                                        </ButtonGroup>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <MobileStepper
                                            variant="progress"
                                            steps={coaching.nbSeances + 1}
                                            position="static"
                                            activeStep={((getArrayOfSeanceEnded(coaching.seances) && getArrayOfSeanceEnded(coaching.seances).length)) || 0}
                                            sx={{ maxWidth: 300, flexGrow: 1, mt: 1, backgroundColor: '#fff' }}
                                            LinearProgressProps={{style: {width: '100%', height: '7px'}}}
                                        />
                                        {coaching.seances && <>
                                            {shouldShowCloseButton(coaching) ? <ButtonGroup variant='contained' color='secondary' sx={{color: 'white', mt: 4}}>
                                                <Button sx={{color: 'white'}} onClick={() => setOpenExtensionPopup({coaching: coaching, isFromDashboard: true})}>
                                                    Prolonger
                                                </Button>
                                                <Button sx={{color: 'white'}} onClick={() => handleOpenConfirmationPopup({coaching, setOpenConfirmationPopup, isFromDashboard: true})}>
                                                    Clôturer
                                                </Button>
                                            </ButtonGroup> : 
                                            <ButtonGroup variant='contained' color='secondary' sx={{color: 'white', mt: 4}}>
                                                {shouldShowSignedButton(coaching) ? <Button sx={{color: 'white'}} disabled={disable} onClick={() => handleSignePopup({coaching, setOpenBackdrop, setOpenSignePopup, setTransition, setText, setSeverity, setOpenSnackbar})}>Signer le contrat de coaching</Button> :
                                                shouldShowContractsButton(coaching) && <Button sx={{color: 'white'}} disabled={disable} onClick={() => handleContractPopup({coaching, setOpenContractPopup, isFromDashboard: true})}>Générer le contrat de coaching</Button>}
                                                {shouldShowChangeCoachButton(coaching) && <Button sx={{color: 'white'}} disabled={disable} onClick={() => handleOpenConfirmationPopup({coaching, setOpenConfirmationPopup, isFromDashboard: true})}>Changer de coach</Button>}
                                            </ButtonGroup>}
                                        </>}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Zoom>
                    )
                }) : 
                <Zoom in={zoom} timeout={700} style={{ transitionDelay: 100 + 200 + 'ms' }}>
                    <Paper elevation={16} sx={{ mt: 3, padding: 4}}>
                        <Typography variant='h6'>Pas de coaching en cours pour le moment...</Typography>
                    </Paper>
                </Zoom>}
            </div>
    )
}