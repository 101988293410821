import {
    Button,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    LinearProgress,
    Paper,
    Slide,
    Snackbar,
    Typography,
    Zoom,
    Tooltip,
} from '@mui/material';
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import MuiAlert from '@mui/material/Alert';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { isKnownRoutes } from '../helper/utils';
import { apiFetch } from '../service/security/apiFetch';
import { withStyles } from '@mui/styles';

function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
}

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
}))(Tooltip);

export function HandleResetPassword() {
    const [disable, setDisable] = React.useState(false)
    const [view, setView] = React.useState(false)
    const [error, setError] = React.useState(false);
    const [severity, setSeverity] = React.useState(false);
    const [text, setText] = React.useState(false);
    const [matchPass, setMatchPass] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [success, setSuccess] = React.useState(false)
    const [transition, setTransition] = React.useState(undefined);
    let navigate = useNavigate()
    const { id } = useParams();

    useEffect(() => {
        (async () => {
            if (isKnownRoutes(window.location.pathname) === 'initPasswordId') {
                const isAuthorize = await apiFetch(`/reset-password/token`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({token: id})
                })
                if (isAuthorize) {
                    setView('initPass')
                } else {
                    setText('Désolé le lien que vous avez reçu par mail est expiré, vous allez être redirigé !')
                    setTransition(() => TransitionLeft)
                    setSeverity('error')
                    setSuccess(true)
                    setTimeout(() => {
                        navigate('/login')
                    }, 4000)
                }
            } else {
                setView('resetPass')
            }
        })()
        
    }, [id, navigate])

    const handleFormSubmit = async (e) => {
        setError(false)
        e.preventDefault()
        let data = new FormData(e.target)
        const newPass = data.get('password')
        const confirmPass = data.get('confirmPass')

        if (newPass === confirmPass) {
            setMatchPass(false)
        } else {
            return setMatchPass('Les deux mots de passe ne correspondent pas')
        }
        setLoading(true)
        setDisable(true)
        
        const changePass = await apiFetch(
            '/reset-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token: `${id}`, password: `${newPass}` })
        }, true)

        if (changePass[0] || changePass.message) {
            setError(changePass[0])
            if (changePass.message) {
                setText('Désolé le lien que vous avez reçu par mail est expiré !')
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setSuccess(true)
            } 
        } else {
            setSeverity('success')
            isInit() ? setText('Félicitations, votre mot de passe a bien été sauvegardé !') :
            setText('Félicitations, votre mot de passe a bien été changé !')
            setTransition(() => TransitionLeft)
            setSuccess(true)
            setTimeout(() => {
                navigate('/login?tab=login')
            }, 3000)
        }
        setLoading(false)
        setDisable(false)
    }

    const handleClose = () => {
        setSuccess(false);
    };

    const [newValues, setNewValues] = React.useState({
        password: '',
        showPassword: false,
    });
    
    const [ConfirmValues, setConfirmValues] = React.useState({
      password: '',
      showPassword: false,
    });

    const isInit = () => {
        return view === 'initPass'
    }
    
    const handleChangePass = (prop) => (event) => {
        setNewValues({ ...newValues, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setNewValues({ ...newValues, showPassword: !newValues.showPassword });
    };

    const handleConfirmPass = (prop) => (event) => {
        setConfirmValues({ ...ConfirmValues, [prop]: event.target.value });
    };

    const handleClickShowConfirmPassword = () => {
        setConfirmValues({ ...ConfirmValues, showPassword: !ConfirmValues.showPassword });
    };

    return (
        <Zoom in={true} timeout={700}>
            <Grid container direction="column" alignItems="center">
                {view && <Grid item xs={12}>
                    <Paper elevation={16} sx={{mt: 3, padding: 4,  width: 600, height: 400, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                    <form noValidate autoComplete="off" onSubmit={ handleFormSubmit }>
                            <Typography variant="h4" color="primary" gutterBottom style={{marginBottom: '40px'}}>
                                {isInit() ? 'Choisissez votre mot de passe' : 'Changer votre mot de passe'}
                            </Typography>
                            <HtmlTooltip
                                title={
                                    <>
                                        <Typography color="inherit">Votre mot de passe doit contenir au moins: </Typography>
                                        <ul>
                                            <li><b>{"Une majuscule"}</b></li>
                                            <li><b>{"Une minuscule"}</b></li>
                                            <li><b>{"Un chiffre"}</b></li>
                                            <li><b>{"8 caractères minimum"}</b></li>
                                        </ul>
                                    </>
                                }
                            >
                                <FormControl style={{width: '400px'}}>
                                    <InputLabel htmlFor="password" required error={!!error && !!error.field}>Nouveau mot de passe</InputLabel>
                                    <Input
                                        required
                                        id="password"
                                        name="password"
                                        error={!!error && !!error.field}
                                        type={newValues.showPassword ? 'text' : 'password'}
                                        value={newValues.password}
                                        onChange={handleChangePass('password')}
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={handleClickShowPassword}
                                            >
                                              {newValues.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                          </InputAdornment>
                                      }
                                    />
                                </FormControl>
                            </HtmlTooltip>
                            <FormHelperText style={{color: 'red', marginBottom: '40px', marginLeft: '18px'}}>{error && error.message}</FormHelperText>
                            <FormControl style={{width: '400px'}}>
                                <InputLabel htmlFor="password" required error={!!matchPass}>Confirmer votre mot de passe</InputLabel>
                                <Input
                                    required
                                    id="confirmPass"
                                    name="confirmPass"
                                    error={!!matchPass}
                                    type={ConfirmValues.showPassword ? 'text' : 'password'}
                                    value={ConfirmValues.password}
                                    onChange={handleConfirmPass('password')}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowConfirmPassword}
                                        >
                                          {ConfirmValues.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                  }
                                />
                            </FormControl>
                            <FormHelperText style={{color: 'red', marginBottom: '40px', marginLeft: '18px'}}>{matchPass}</FormHelperText>
                            <Button
                            disabled={disable}
                            type="submit"
                            variant="contained"
                            color="primary"
                            endIcon={<VpnKeyIcon />}
                            style={{marginTop: '10px'}}>
                                {isInit() ? 'Sauvegarder le mot de passe' : 'Changer le mot de passe'}
                            </Button>
                        </form>
                    </Paper>
                    {loading && <LinearProgress />}
                </Grid>}
                {success && <Snackbar
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                    open={success}
                    onClose={handleClose}
                    TransitionComponent={transition}
                    key={transition ? transition.name : ''}
                >
                    {severity && <MuiAlert elevation={6} variant="filled" severity={severity} >{text}</MuiAlert>}
                </Snackbar>}
            </Grid>
        </Zoom>
    )
}