import React, { createRef, useEffect, useState } from 'react'
import { TransitionLeft } from '../helper/utils'
import { apiFetch } from '../service/security/apiFetch'
import { useNavigate } from 'react-router-dom';
import {
    Zoom,
    Paper,
    Typography,
    Grid,
    Button,
    Toolbar,
    CircularProgress,
    LinearProgress,
} from '@mui/material/';
import moment from 'moment'
import AddSessionCoachingPopup from './CoachingItem/AddSessionCoachingPopup';
import Avatar from '@mui/material/Avatar';
import { BottomOfPage } from '../components/BottomOfPage';
import { useCompanies } from '../hook/companies';
import { useManagers } from '../hook/managers';
import { handleFormError } from '../service/security/error';

export default function CoachingSessions({ zoom, setOpenSnackbar, setText, setTransition, setSeverity, setCoachings }) {
    const [coachingsSessions, setCoachingsSessions] = useState(false)
    const [openAddPopup, setOpenAddPopup] = useState(false)
    const [company, setCompany] = useState('')
    const [manager, setManager] = useState('')
    const [sessionName, setSessionName] = useState('')
    const [spinner, setSpinner] = useState(false)
    const [error, setError] = useState({})
    const [disable, setDisable] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [view, setView] = useState(false)
    const target = createRef();
    let navigate = useNavigate()
    document.title = 'Wellmonday - Sessions de coachings'

    const {
        fetchCompanies,
        createCompanies,
        companies
    } = useCompanies()

    const {
        fetchManagers,
        createManagers,
        managers,
    } = useManagers()

    useEffect(() => {
        (async () => {
            const fetchCoachingsSessions = await apiFetch('/coaching_sessions')
            if (fetchCoachingsSessions && fetchCoachingsSessions['hydra:member']) {
                setCoachingsSessions(fetchCoachingsSessions['hydra:member'])
                setView(fetchCoachingsSessions['hydra:view'])
            } else {
                setTransition(() => TransitionLeft)
                setText('Erreur lors du chargement des données')
                setSeverity('error')
                setOpenSnackbar(true)
                navigate(-1)
            }
        })()
    }, [setText, setTransition, setSeverity, setOpenSnackbar, setCoachingsSessions, navigate])

    useEffect(() => {
        (async () => {
            const companiesFetch = await fetchCompanies('/companies?pagination=false')
            if (companiesFetch && companiesFetch['hydra:description']) {
                setTransition(() => TransitionLeft)
                setText('Erreur lors du chargement des données')
                setSeverity('error')
                setOpenSnackbar(true)
                navigate(-1)
            }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setText, setTransition, setSeverity, setOpenSnackbar, navigate])

    const handleAddCoaching = async () => {
        setDisable(true)
        if (!company) {
            setDisable(false)
            return setError({company: 'Ce champ ne doit pas être vide !'})
        }
        if (!manager) {
            setDisable(false)
            return setError({manager: 'Ce champ ne doit pas être vide !'})
        }
        const addCoaching = await apiFetch('/coaching_sessions', {
            method: 'POST',
            body: JSON.stringify({
                name: sessionName,
                company: company && company['@id'],
                manager: manager && manager['@id'],
            })
        })
        if (addCoaching && addCoaching.uuid) {
            setTransition(() => TransitionLeft)
            setText('La session a été créée')
            setSeverity('success')
            setOpenSnackbar(true)
            handleShowCoaching(addCoaching)
            setOpenAddPopup(false)
        } else {
            const formError = handleFormError(addCoaching, ['name', 'company', 'manager'])
            setError(formError)
            setTransition(() => TransitionLeft)
            setText('Erreur lors la création de la session')
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setDisable(false)
    }

    const handleShowCoaching = (coaching) => {
        setCoachings(false)
        navigate(`/coaching/${coaching.uuid}/entreprise/${coaching.company.uuid}`)
    }

    const enterHandler = async () => {
        setSpinner(true)
        if (view && view['hydra:next']) {
            const fetchCoachingsSessions = await apiFetch(view['hydra:next'])
            if (fetchCoachingsSessions) {
                setCoachingsSessions((fetchCoachingsSessions && coachingsSessions.concat(fetchCoachingsSessions['hydra:member'])))
                setView(fetchCoachingsSessions['hydra:view'])
            }
        }
        setSpinner(false)
    };

    const handleManagers = async (value) => {
        setRefresh(true)
        setManager('')
        if (value && value.uuid) {
            const managersFetch = await fetchManagers(`/managers?company.uuid=${value.uuid}`)
            if (managersFetch && managersFetch['hydra:description']) {
                setTransition(() => TransitionLeft)
                setText('Erreur lors du chargement des données')
                setSeverity('error')
                setOpenSnackbar(true)
                navigate(-1)
            }
        }
        setRefresh(false)
    }

    return (
        <div style={{marginTop: 50}} ref={target}>
            <BottomOfPage target={target} func={enterHandler} />
            <Toolbar>
                <Typography variant='h5' sx={{fontWeight: 'bold', flex: '1 1 100%'}} color='secondary'>LISTE DES SESSIONS DE COACHINGS</Typography>
                <Button variant='contained' color='primary' onClick={() => setOpenAddPopup(true)}>Ajouter une session</Button>
            </Toolbar>
            <AddSessionCoachingPopup error={error} disable={disable} createCompanies={createCompanies} createManagers={createManagers} refresh={refresh} func={handleManagers} setManager={setManager} manager={manager} managers={managers} companies={companies} setSessionName={setSessionName} sessionName={sessionName} setCompany={setCompany} company={company} open={openAddPopup} handleClose={() => setOpenAddPopup(false)} handleAdd={handleAddCoaching} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} />
            {coachingsSessions ? coachingsSessions.map((coaching, index) => {
                return (
                    <Zoom in={zoom} timeout={700} key={index}  style={{ transitionDelay: index * 100 + 200 + 'ms', cursor: 'pointer' }}>
                        <Paper elevation={16} onClick={() => handleShowCoaching(coaching)} sx={{mt: 3, padding: 4,  paddingBottom: 1,':hover': {boxShadow: 5},}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={8}>
                                    <div style={{display: 'flex'}}>
                                        <Avatar src={coaching.company.logo && coaching.company.logo.contentUrl} style={{marginRight: 20}} />
                                        <Typography variant='h6' color='primary'>{coaching.company.name}</Typography>
                                    </div>
                                    <div style={{marginTop: 20, color: '#707070'}}>
                                        <Typography variant='body1' color='secondary'><span style={{fontStyle: 'italic'}}>Nom de la session :</span> {coaching.name}</Typography>
                                        <Typography variant='body1' color='secondary'><span style={{fontStyle: 'italic'}}>Date de la session :</span> {moment(coaching.createdAt).format('DD MMMM YYYY à HH:mm')}</Typography><br />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div style={{marginTop: 10, textAlign: 'right'}}>
                                        <Typography variant='body1'>{'Nombre de coaching : ' + coaching.nbCoachings}</Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Zoom>
                )
            }) : <LinearProgress sx={{mt: 4}} />}
            {spinner && <div style={{textAlign: 'center'}}><CircularProgress color={'primary'} /></div>}
        </div>
    )
}